
import {
  createRouter,
  // createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { createApp } from "vue";
import App from "./App.vue";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css'
import "./assets/font/css/all.min.css";


import HomePage from './components/HomePage.vue'
import Service from './components/Service.vue'
import About from './components/About.vue'
import Policy from './components/Policy.vue'
import Terms from './components/Terms.vue'
import Contact from './components/Contact.vue'
import qoute from './components/qoute.vue'

import web from './components/services/web_development.vue'
import app from './components/services/app_development.vue'
import seo from './components/services/SEO.vue'
import graphic from './components/services/graphic.vue'
import consultancy from './components/services/consultancy.vue'
import digital_marketing from './components/services/digital_marketing.vue'

/*import Cononstruction from './components/constructionpage.vue'
import AboutPage from './components/AboutPage.vue'
import FAQPage from './components/FAQPage.vue'
import ContactPage from './components/ContactPage.vue'
import TermsPage from './components/TermsPage.vue'
import TestimoniesPage from './components/TestimoniesPage.vue'
import LoginPage from './components/LoginPage.vue'
import SignupPage from './components/SignupPage.vue'
import Forgotpassword from './components/Forgotpassword.vue'
import Congratulation from './components/Congratulation.vue'*/


const routes =[
{path:'/',component:HomePage},
{path:'/home',component:HomePage},
{path:'/services',component:Service},
{path:'/About',component:About},
{path:'/Policy',component:Policy},
{path:'/Contact',component:Contact},
{path:'/Terms and conditions',component:Terms},
{path:'/qoute',component:qoute},


{path:'/web_development',component:web},
{path:'/app_development',component:app},
{path:'/SEO',component:seo},
{path:'/graphics_designing',component:graphic},
{path:'/consultancy',component:consultancy},
{path:'/digital_marketing',component:digital_marketing},

]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


const xapp = createApp(App);

xapp.use(router).mount("#app");
