<template>
  <div class="head">
    <div class="row">
      <div class="col-md-3">
        <span class="mimgcont desknone">
          <i
            class="fa fa-bars favicon_style menuimg"
            v-on:click="showmenu()"
          ></i>
          <i
            class="fa fa-xmark favicon_style back none"
            v-on:click="hidemenu()"
          ></i>

          <!-- <img
            src="../../assets/menu.png"
            alt=""
            class="menuimg img1"
            v-on:click="showmenu()"
          /> -->

          <!-- <img
            src="../../assets/x.png"
            alt=""
            class="back none"
            v-on:click="hidemenu()"
          /> -->
        </span>
        <router-link to="/">
          <span class="sitename animate__animated animate__zoomIn"
            >Echelon Tech</span
          >
        </router-link>
        <!-- <img src="../../assets/logo0.png" alt="" class="logoo">   -->
      </div>

      <div class="col-md-6">
        <div class="navcont resnone">
          <router-link to="/home" class="navs df">Home</router-link>
          <!-- <router-link to="/Services" class="navs">Services</router-link>-->
          <div class="ib navwithsub" v-on:click="showsubnav()">
            Services <i class="fa fa-angle-down adown" id="adown"></i
            ><i class="fa fa-angle-up none" id="aup"></i>
            <!-- <b class="drop drop1">^</b> -->
            <div class="subnav none">
              <router-link to="/web_development" class="sub_navs"
                >Web development</router-link
              >
              <router-link to="/app_development" class="sub_navs"
                >App development</router-link
              >
              <router-link to="/graphics_designing" class="sub_navs"
                >Grapic Designing</router-link
              >
              <router-link to="/SEO" class="sub_navs">SEO</router-link>
              <router-link to="/digital_marketing" class="sub_navs"
                >Digital Marketing</router-link
              >
              <router-link to="/consultancy" class="sub_navs"
                >Consultancy</router-link
              >
            </div>
          </div>
          <!--<router-link to="/" class="navs">Blog</router-link>-->
          <div class="navs ib df" hidden>Blog</div>
          <router-link to="/Contact" class="navs df">Contact us</router-link>
          <router-link to="/About" class="navs df">About us</router-link>
          <router-link to="/policy" class="navs df">Company Policy</router-link>
        </div>
      </div>

      <div class="col-md-3 resnone">
        <router-link to="/qoute" class="action_btn text-light"
          >Get a Qoute</router-link
        >
      </div>
    </div>
  </div>
</template>

<script scoped>
import $ from "jquery";

export default {
  name: "Header",
  methods: {
    showsubnav: function () {
      $("#adown").toggle();
      $("#aup").toggle();
      $(".subnav").toggle(500);
    },
    showmenu: function () {
      $(".resmenu").show(1000);
      $(".back").css("display", "inline-block");
      $(".menuimg").hide();
      // $("body").css('margin-left','30%');
    },
    hidemenu: function () {
      $(".resmenu").hide(1000);
      $(".back").hide(0);
      $(".menuimg").show();
      // $("body").css('margin-left','0%');
    },
  },
  mounted() {
    $(".navs,.sub_navs").click(function () {
      $(".subnav").hide(500);
    });
    $(document).scroll(function () {
      $(".subnav").hide(500);
      $("#adown").show();
      $("#aup").hide();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only  #101112;  #343c66  ,.navwithsub:hover-->
<style scoped>
.favicon_style {
  font-size: 26px;
  color: #086060;
  display: inline-block;
  margin-top: 8px;
  margin-right: 10px;
}
/* .back {
    margin-top: 18px;
    margin-right: 15px;
    display: inline-block;
  } */
.logoo {
  width: 200px;
  height: 60px;
  margin-top: -10px;
}

.navs,
.navwithsub {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.navs:hover,
.navwithsub:hover {
  color: #086060;
  font-weight: bolder;
}
.head {
  padding: 30px 40px 0px 40px;
  background-color: white;
  position: fixed;
  height: 80px;
  width: 100%;
  z-index: 999;
  top: 0px;
  box-shadow: 2px 2px 2px 2px #5ab1b15b;
} /* top right bottom left     box-shadow:0px 1px 0px 0px rgba(255, 255, 255, 0.801);*/

.desknone {
  display: none;
}
.none {
  display: none;
}
.site_name {
  display: inline-block;
}
.subnav {
  position: absolute;
  background-color: white;
  margin-top: 16px;
  padding: 14px;
  padding-bottom: 4px;
  border-bottom: 2px solid #086060;
  padding-left: 5px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  gap: 20px;
  -ms-flex-order: 2;
  left: 15%;
  width: 600px;
}

/* border-bottom-left-radius: 8px;padding-bottom: 4px;border-bottom-right-radius: 8px; */
.subnav:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: #086060;
  margin-top: -26px;
  margin-left: 0px;
}

.sub_navs {
  color: black;
  display: inline-block;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: lighter;
  margin-right: 15px;
  margin-left: 15px;
  width: 25%;
}
.ib {
  display: inline-block;
}
.none {
  display: none;
}
.df {
  font-weight: lighter;
}

.sitename {
  font-size: 20px;
  text-align: left;
  text-transform: uppercase;
  color: #086060;
  margin-top: 20px;
}
.action_btn {
  width: 200px;
  padding: 7px;
  height: 32px;
  margin-left: 0px;
  margin-left: 20%;
  /* display: inline-block; */
  /* border: 2px solid #086060; */
  padding-top: 5px;
  background-color: #086060;
  color: white;
  text-align: center;
  font-size: 17px;
  border-radius: 4px;
  text-decoration: none;
  height: 45px;
  font-weight: bold;
  transition: ease-in 1s;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  /* flex-shrink: 0;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: top;
  font-style: normal;
  text-align: center; */
}
/* .navcont {
  margin-top: 10px;
} */
.action_btn:hover {
  background-color: black;
  border: none;
  color: white;
}
.router-link-active {
  color: #086060;
  font-weight: bolder;
}
.drop {
  transform: rotate(180deg);
  display: inline-block;
  margin-top: -73px;
  margin-left: -3px;
}

@media screen and (max-width: 480px) {
  .resnone {
    display: none;
  }
  .head {
    padding: 5px 10px 0px 10px;
    height: 60px;
    padding-top: 10px;
  }
  .logoo {
    width: 140px;
    height: 35px;
    margin-top: -20px;
  }
  .mimgcont {
    display: inline-block;
  }
  /* .menuimg,
  .back {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    float: left;
    margin-top: 5px;
    background-color: #086060;
    padding: 5px;
    clear: both;
  } */
  .sitename {
    font-size: 20px;
    margin-top: 5px;
    position: absolute;
  }
}
</style>
