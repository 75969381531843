<template>
  <div class="cont">
    <Header />

    <div class="home">
      <h4 class="sectionheader">Privacy Policy</h4>
      <br />
      <br />
      <br />
      <div class="row row2 write">
        <h4 class="sectionheadersub">GENERAL INFORMATION</h4>
        Welcome to our Privacy Policy page! When you use our web site services,
        you trust us with your information. This Privacy Policy is meant to help
        you understand what data we collect, why we collect it, and what we do
        with it. When you share information with us, we can make our services
        even better for you. For instance, we can show you more relevant search
        results and ads, help you connect with people or to make sharing with
        others quicker and easier. As you use our services, we want you to be
        clear how we’re using information and the ways in which you can protect
        your privacy. This is important; we hope you will take time to read it
        carefully. Remember, you can find controls to manage your information
        and protect your privacy and security. We’ve tried to keep it as simple
        as possible.
      </div>

      <div class="row row2 write">
        <h4 class="sectionheadersub">
          RIGHT TO ACCESS, CORRECT AND DELETE DATA AND TO OBJECT TO DATA
          PROCESSING
        </h4>

        Our customers have the right to access, correct and delete personal data
        relating to them, and to object to the processing of such data, by
        addressing a written request, at any time. The Company makes every
        effort to put in place suitable precautions to safeguard the security
        and privacy of personal data, and to prevent it from being altered,
        corrupted, destroyed or accessed by unauthorized third parties. However,
        the Company does not control each and every risk related to the use of
        the Internet, and therefore warns the Site users of the potential risks
        involved in the functioning and use of the Internet. The Site may
        include links to other web sites or other internet sources. As the
        Company cannot control these web sites and external sources, the Company
        cannot be held responsible for the provision or display of these web
        sites and external sources, and may not be held liable for the content,
        advertising, products, services or any other material available on or
        from these web sites or external sources.
      </div>

      <div class="row row2 write">
        <h4 class="sectionheadersub">MANAGEMENT OF PERSONAL DATA</h4>
        You can view or edit your personal data online for many of our services.
        You can also make choices about our collection and use of your data. How
        you can access or control your personal data will depend on which
        services you use. You can choose whether you wish to receive promotional
        communications from our web site by email, SMS, physical mail, and
        telephone. If you receive promotional email or SMS messages from us and
        would like to opt out, you can do so by following the directions in that
        message. You can also make choices about the receipt of promotional
        email, telephone calls, and postal mail by visiting and signing into
        Company Promotional Communications Manager, which allows you to update
        contact information, manage contact preferences, opt out of email
        subscriptions, and choose whether to share your contact information with
        our partners. These choices do not apply to mandatory service
        communications that are part of certain web site services.
      </div>

      <div class="row row2 write">
        <h4 class="sectionheadersub">INFORMATION WE COLLECT</h4>
        Our store collects data to operate effectively and provide you the best
        experiences with our services. You provide some of this data directly,
        such as when you create a personal account. We get some of it by
        recording how you interact with our services by, for example, using
        technologies like cookies, and receiving error reports or usage data
        from software running on your device. We also obtain data from third
        parties (including other companies). For example, we supplement the data
        we collect by purchasing demographic data from other companies. We also
        use services from other companies to help us determine a location based
        on your IP address in order to customize certain services to your
        location. The data we collect depends on the services and features you
        use.
      </div>

      <div class="row row2 write">
        <h4 class="sectionheadersub">HOW WE USE YOUR INFORMATION</h4>
        Our web site uses the data we collect for three basic purposes: to
        operate our business and provide (including improving and personalizing)
        the services we offer, to send communications, including promotional
        communications, and to display advertising. In carrying out these
        purposes, we combine data we collect through the various web site
        services you use to give you a more seamless, consistent and
        personalized experience. However, to enhance privacy, we have built in
        technological and procedural safeguards designed to prevent certain data
        combinations. For example, we store data we collect from you when you
        are unauthenticated (not signed in) separately from any account
        information that directly identifies you, such as your name, email
        address or phone number.
      </div>

      <div class="row row2 write">
        <h4 class="sectionheadersub">SHARING YOUR INFORMATION</h4>
        We share your personal data with your consent or as necessary to
        complete any transaction or provide any service you have requested or
        authorized. For example, we share your content with third parties when
        you tell us to do so. When you provide payment data to make a purchase,
        we will share payment data with banks and other entities that process
        payment transactions or provide other financial services, and for fraud
        prevention and credit risk reduction. In addition, we share personal
        data among our controlled affiliates and subsidiaries. We also share
        personal data with vendors or agents working on our behalf for the
        purposes described in this statement. For example, companies we've hired
        to provide customer service support or assist in protecting and securing
        our systems and services may need access to personal data in order to
        provide those functions. In such cases, these companies must abide by
        our data privacy and security requirements and are not allowed to use
        personal data they receive from us for any other purpose. We may also
        disclose personal data as part of a corporate transaction such as a
        merger or sale of assets.
      </div>
    </div>
    <!--- home end-->

    <Resmenu />

    <Footer class="ft" />
  </div>
</template>

<script>
//import $ from "jquery"

import Header from "./plugins/header.vue";
import Footer from "./plugins/Footer.vue";
import Resmenu from "./plugins/rmenu.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}

body {
  overflow: hidden;
}
.sectionheader {
  color: #086060;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: -50px;

}
.sectionheadersub {
  color: #086060;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 20px;
}

.home {
  padding: 50px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 120px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 30px;
  padding-top: 0px;
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .home {
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 80px;
  }
  .sectionheader {
    margin-bottom: -30px;
  }
  .main {
    font-size: 13px;
    color: white;
    text-align: center;
    padding: 4px;
  }
  .sectionheadersub {
    color: #086060;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .write {
    padding: 10px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
  }
}
</style>
