<template>
  <div class="cont">
    <Header />

    <div class="home">
      <h4 class="sectionheader">Terms and Conditions</h4>
      <marquee class="m"
        ><b>Please read the following before you transact with us.</b></marquee
      >
      <br />
      <br />
      <br />
      <div class="row row2 write">
        <h4 class="sectionheadersub">ACCEPTANCE OF TERMS</h4>
        The Services provided by Echelon Tech LLC. for you is subject to the
        following Terms and Conditions (“T&C”). We reserve the right to update
        the T&C at any time without notice to you. The most current version of
        the T&C can be reviewed by clicking on the “Terms & Conditions”
        hyperlink located at the bottom of our Web pages.
      </div>

      <div class="row row2 write">
        <h4 class="sectionheadersub">
          NOTICE SPECIFIC TO DOCUMENTS AVAILABLE ON THIS WEB SITE:
        </h4>
        Permission to use Documents from the Content is granted, provided that:
        » use of such Documents from the Content is for informational and
        non-commercial or personal use only and will not be copied or posted on
        any network computer or broadcast in any media. No modifications of any
        Documents are made. Accredited educational institutions, such as
        universities and private/public colleges, may download and reproduce the
        Documents for distribution in the classroom. Distribution outside the
        classroom requires express written permission. Use for any other purpose
        is expressly prohibited by law, and may result in severe civil and
        criminal penalties. Violators will be prosecuted to the maximum extent
        possible. Documents specified above include the design or layout of the
        Echelon Tech LLC web site. Elements of our web site are protected by
        trade dress, trademark, unfair competition, and other laws and may not
        be copied or imitated in whole or in part. No logo, graphic or image
        from our web site may be copied or retransmitted unless expressly
        permitted by Echelon Tech LLC. We make no representations about the
        suitability of the information contained in the documents and related
        graphics published as part of the Content for any purpose. All such
        documents and related graphics are provided “as is” without warranty of
        any kind. We hereby disclaim all warranties and conditions with regard
        to this information, including all warranties and conditions of
        merchantability, whether express, implied or statutory, fitness for a
        particular purpose, title and non-infringement. In no event shall SNP
        Infotech be liable for any special, indirect or consequential damages or
        any damages whatsoever resulting from loss of use, data or profits,
        whether in an action of contract, negligence or other tortuous action,
        arising out of or in connection with the use or performance of
        information available from the Content.
      </div>

      <div class="row row2 write">
        <h4 class="sectionheadersub">NO SUBMISSION OF IDEA POLICY</h4>
        The sole purpose of this policy is to avoid potential misunderstandings
        or disputes when our products or marketing strategies might seem similar
        to ideas submitted to us. So, please do not send your unsolicited ideas
        to us. If, ' despite our request that you not send us your ideas and
        materials, you still send them, please understand that we make no
        assurances that your ideas and materials will be treated as confidential
        or proprietary.
      </div>
    </div>
    <!--- home end-->

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from "./plugins/header.vue";
import Footer from "./plugins/Footer.vue";
import Resmenu from "./plugins/rmenu.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}

body {
  overflow: hidden;
}
.sectionheader {
  color: #086060;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 20px;
}

.home {
  padding: 50px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 120px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 30px;
  padding-top: 0px;
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .home {
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 80px;
  }
  .sectionheader {
    margin-bottom: -30px;
  }
  .main {
    font-size: 13px;
    color: white;
    text-align: justify;
    padding: 4px;
  }
  .sectionheadersub {
    color: #086060;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .write {
    padding: 10px;
    padding-top: 0px;
    font-size: 14px;
    text-align: justify;
  }
  .m {
    margin-top: 60px;
  }
}
</style>
