<template>
  <div class="cont">
    <!--<Loader class="load"/>
<div class="after_loader none">-->

    <Header />
    <Slide />

    <div class="home">
      <br />
      <br />
      <br />
      <div class="row aboutrow r1 r">
        <div class="col-md-7">
          <h4
            class="sectionheader text-left"
            data-aos="slide-up"
            style="text-align: left"
          >
            About Us
          </h4>
          <div class="main text-dark" data-aos="slide-up">
            Echelon Tech is a dynamic and forward-thinking technology firm
            dedicated to pioneering innovation and driving positive change in
            the digital landscape. Established with a vision to revolutionize
            the way businesses operate and individuals interact with technology,
            Echelon Tech has evolved into a trusted partner for organizations
            seeking to leverage the power of cutting-edge solutions.
            <router-link to="/About" class="action_btn rm"
              >ReadMore <span class="fa fa-angle-right"></span
            ></router-link>
          </div>
        </div>
        <!-- <div class="col-md-1"></div> -->
        <div class="col-md-5">
          <img
            src="../assets/slide2.svg"
            alt=""
            class="aboutimg"
            data-aos="slide-up"
          />
        </div>
      </div>

      <div class="forbg">
        <div class="aboutus row" data-aos="zoom-in">
          <!--about cont -->
          <div class="col-md-4">
            <div class="card mission_card">
              <h4 class="card_name">Our Mission</h4>
              our mission is to harness the power of technology to solve
              real-world problems, enhance productivity, and improve the quality
              of life for our customers.
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mission_card">
              <h4 class="card_name">Our Vision</h4>
              Our vision is to be a global leader in technology innovation,
              empowering businesses and individuals to achieve their full
              potential through advanced and sustainable solutions.
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mission_card">
              <h4 class="card_name">Our Values</h4>
              Innovation: We believe in the power of innovation to transform
              industries, disrupt the status quo, and unlock new possibilities.
              We are powered by innovation.
            </div>
          </div>
        </div>

        <div class="services">
          <h4 class="sectionheader">Our Services</h4>
          <div class="service_row1 row" data-aos="zoom-in">
            <!--service cont -->
            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/web developmnet.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Web development</h5>
                  <div class="service_about">
                    Our goal is to to optimize user‘s experience. we are
                    commited to helping you grow your business.

                    <span class="readmore"
                      ><router-link to="/web_development" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/mobile app.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Mobile app development</h5>
                  <div class="service_about">
                    We have team of developers with,proficiency and skilled
                    expertise as well as experience in android application
                    Development.
                    <span class="readmore"
                      ><router-link to="/app_development" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/digital marketing.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Digital marketing</h5>
                  <div class="service_about">
                    We generate demand for your Business, So You Could Continue
                    What You Do The Best!
                    <span class="readmore"
                      ><router-link to="/digital_marketing" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- second service card -->

          <div class="service_row1 row" data-aos="zoom-in">
            <!--service cont -->
            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/consult.svg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Consultancy</h5>
                  <div class="service_about">
                    Echelon Tech LLC is a business consultant focused on growth
                    strategy and implementation.
                    <span class="readmore"
                      ><router-link to="/consultancy" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/graphic design.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Graphics Designing</h5>
                  <div class="service_about">
                    Beautiful hand-made professional graphic design for
                    websites, branding and print materials.
                    <span class="readmore"
                      ><router-link to="/graphics_designing" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img src="../assets/seo.jpg" alt="" class="service_card_img" />
                <div class="service_write">
                  <h5 class="service_head">Search Engine Optimization</h5>
                  <div class="service_about">
                    we are a strategic digital Marketing agency powered by data
                    analytics, market research, and digital technologies.
                    <span class="readmore"
                      ><router-link to="/SEO" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br /><br />
        <div class="whychoose row">
          <!--whychooose cont -->
          <h4 class="sectionheader">Why Choose Echelon tech</h4>
          <div class="w">
            <div class="whycont row whycont1">
              <div class="col-md-6 mainwhy" data-aos="zoom-in">
                <!-- <img src="../assets/money.png" class="whyimg" /> -->
                <i class="fa fa-money-bill favicon_style"></i>
                <div class="whymsg">
                  <div class="whyh">Cost Effective</div>
                  Build high quality and cost-effective softwares, our business
                  solutions are very affordable.
                </div>
              </div>
              <div class="col-md-6 mainwhy" data-aos="zoom-in">
                <i class="fas fa-user-tie favicon_style"></i>
                <!-- <img src="../assets/w1.png" class="whyimg" /> -->
                <div class="whymsg">
                  <div class="whyh">Professionalism</div>
                  Echelon Tech features a band of experienced,skilled and
                  talented developers in differnt spheres of development.
                </div>
              </div>
            </div>

            <div class="whycont row whycont2">
              <div class="col-md-6 mainwhy" data-aos="zoom-in">
                <!-- <img src="../assets/w3.png" class="whyimg" /> -->
                <i class="far fa-lightbulb favicon_style"></i>

                <div class="whymsg">
                  <div class="whyh">Fast Delivery</div>
                  Our main goal is to deliver clean jobs on time.
                </div>
              </div>
              <div class="col-md-6 mainwhy" data-aos="zoom-in">
                <!-- <img src="../assets/w2.png" class="whyimg" /> -->
                <i class="fa fa-clock favicon_style"></i>

                <div class="whymsg">
                  <div class="whyh">24/7 customer support</div>
                  Even after buiding your projects, we offer full time customer
                  support.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--thebg cont end -->

      <div class="skillsetcont us row resnone" hidden>
        <!--whychooose cont data-aos-easing="ease-in" data-aos-delay="100"-->
        <h4 class="sectionheader" data-aos="slide-up">Our clients rating</h4>

        <div class="row">
          <div class="col-6 clients rate" style="padding-top: 20px">Our clients service rating 
          </div>
          <div class="col-6">
            <div class="twoskillrow">
              <!--skillrow 1-->
              <div class="skill sl" data-aos="fade-in">
                <!--skill 1 in row-->
                <div class="skillname">
                  Fast Delivery <span class="nopercent webpercent">95%</span>
                </div>
                <div class="percentage">
                  <div class="percentage_guage webdev"></div>
                </div>
              </div>

              <div class="skill">
                <!--skill 2 in row-->
                <div class="skillname" data-aos="fade-in">
                  Customer Service <span class="nopercent apppercent">75%</span>
                </div>
                <div class="percentage">
                  <div class="percentage_guage appdev"></div>
                </div>
              </div>
            </div>

            <div class="twoskillrow">
              <!--skillrow 3-->
              <div class="skill sl" data-aos="fade-in">
                <!--skill 1 in row-->
                <div class="skillname">
                  Search Engine Optimization<span class="nopercent seopercent"
                    >65%</span
                  >
                </div>
                <div class="percentage">
                  <div class="percentage_guage seo"></div>
                </div>
              </div>

              <div class="skill" data-aos="fade-in">
                <!--skill 2 in row-->
                <div class="skillname">
                  Grapgics Designing
                  <span class="nopercent graphicspercent">90%</span>
                </div>
                <div class="percentage">
                  <div class="percentage_guage graphics"></div>
                </div>
              </div>
            </div>

            <div class="twoskillrow" hidden>
              <!--skillrow 3-->
              <div class="skill sl" data-aos="fade-in">
                <!--skill 1 in row-->
                <div class="skillname">
                  Digital Marketing<span class="nopercent digitalpercent"
                    >70%</span
                  >
                </div>
                <div class="percentage">
                  <div class="percentage_guage ux digital"></div>
                </div>
              </div>

              <div class="skill" data-aos="fade-in">
                <!--skill 2 in row-->
                <div class="skillname">
                  Consultancy<span class="nopercent consultpercent">100%</span>
                </div>
                <div class="percentage">
                  <div class="percentage_guage de consult"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <div class="whatpeoplesay row">
        <!--whatpeople say cont -->
        <h4 class="sectionheader">What people say</h4>

        <div class="mainwhat">
          <div class="testimony card" data-aos="slide-left">
            <div class="msg">
              I would love to thank you, for your effective SEO services,and for
              your friendly and professional support.
            </div>
            <div class="imgcont">
              <img src="../assets/Godwin.png" alt="" class="testimg" />
            </div>
            <br />
            <div class="testname">Godwin</div>
          </div>

          <div class="testimony card" data-aos="slide-up">
            <div class="msg">
              Your agency has brought a great improvment to my business, through
              their exceptional digital marketing strategy .
            </div>
            <div class="imgcont">
              <img src="../assets/Bright.png" alt="" class="testimg" />
            </div>
            <br />
            <div class="testname">Bright Ogbowu</div>
          </div>

          <div class="testimony card resnone" data-aos="slide-right">
            <div class="msg">
              I can't say much, I just want to thank your agancy, for the
              excellent job they did redesigning my website.
            </div>
            <div class="imgcont">
              <img src="../assets/chinedu.png" alt="" class="testimg" />
            </div>
            <br />
            <div class="testname">Chinedum</div>
          </div>
        </div>
        <!----what people say end -->
      </div>

      <!---- ones with footer below --->
    </div>
    <!---- ones with footer --->

    <div class="mark">
      <div class="marks" data-aos="zoom-in">
        <span class="figure count1">24+</span><span class="figure">+</span
        ><br />
        <span class="figtag">Projects</span>
      </div>
      <div class="marks" data-aos="zoom-in">
        <span class="figure count2">1</span><br /><span class="figtag"
          >Award(s)</span
        >
      </div>
      <div class="marks" data-aos="zoom-in">
        <span class="figure count3">4</span><br /><span class="figtag"
          >Experts</span
        >
      </div>
      <div class="marks" data-aos="zoom-in">
        <span class="figure count4">2</span><br /><span class="figtag"
          >Partners</span
        >
      </div>
    </div>

    <!-- <div class="brandscont">
<h4 class="sectionheader" data-aos="slide-up">Brands that we've worked for</h4>
<div class="brands">
<img src="../assets/b0.jpg" class="brandlogo bl" data-aos="zoom-in">
<img src="../assets/b2.png" class="brandlogo" data-aos="zoom-in">
<img src="../assets/b3.png" class="brandlogo" data-aos="zoom-in">
</div>

<div class="pagnitioncont">
<div class="pagnition pag1"></div>
<div class="pagnition pag3"></div>
</div>
</div> -->

    <Resmenu />

    <Footer class="ft" />
  </div>
  <!--</div>-->
</template>

<script>
//import $ from "jquery"

import Header from "./plugins/header.vue";
import Footer from "./plugins/Footer.vue";
import Slide from "./plugins/slide.vue";
import Resmenu from "./plugins/rmenu.vue";
//import Loader from './plugins/loader.vue'
import AOS from "aos";
import "aos/dist/aos.css";

import anime from "animejs";

export default {
  name: "HomePage",
  components: {
    Slide,
    Header,
    Footer,
    Resmenu,
    //Loader
  },
  mounted() {
    AOS.init();

    anime({
      targets: ".count1",
      innerHTML: [0, 24],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".count2",
      innerHTML: [0, 1],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".count3",
      innerHTML: [0, 4],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".count4",
      innerHTML: [0, 2],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    //////////////////////////////////// for rating////////////////////

    /* webdev  */
    anime({
      targets: ".webpercent",
      innerHTML: [0, 95],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".webdev",
      width: "95%",
      easing: "easeInOutQuad",
      duration: 10000,
    });
    /* webdev end  */

    /* appdev  */
    anime({
      targets: ".apppercent",
      innerHTML: [0, 75],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".appdev",
      width: "75%",
      easing: "easeInOutQuad",
      duration: 10000,
    });
    /* appdev end  */

    /* seo  */
    anime({
      targets: ".seopercent",
      innerHTML: [0, 65],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".seo",
      width: "65%",
      easing: "easeInOutQuad",
      duration: 10000,
    });
    /* seo end  */

    /* graphics */
    anime({
      targets: ".graphicspercent",
      innerHTML: [0, 90],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".graphics",
      width: "90%",
      easing: "easeInOutQuad",
      duration: 10000,
    });
    /* grapgics end  */

    /* digital */
    anime({
      targets: ".digitalpercent",
      innerHTML: [0, 70],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".digital",
      width: "70%",
      easing: "easeInOutQuad",
      duration: 10000,
    });
    /* digital end  */

    /* consult */
    anime({
      targets: ".consultpercent",
      innerHTML: [0, 100],
      easing: "easeOutBounce",
      round: 1,
      duration: 10000,
    });

    anime({
      targets: ".consult",
      width: "100%",
      easing: "easeInOutQuad",
      duration: 10000,
    });
    /* consult end  */

    /*function loader(){
$(".load").hide()
$(".after_loader").show()
}
setTimeout(loader,2500)
*/
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.figtag {
  color: #086060;
}
.favicon_style {
  font-size: 36px;
  color: #086060;
  margin-right: 20px;
}

body {
  overflow: hidden;
  overflow-x: hidden;
}

.home {
  padding: 20px;
  padding-bottom: 0px;
  overflow: hidden;
  overflow-x: hidden;
}
/* .cont{overflow-x: hidden;} */
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

/* #1b1d3a #20283d  #01082c*/
.aboutus {
  margin-bottom: 50px;
  padding: 40px;
  margin-top: 10px;
}
.about_cont {
  font-size: 24px;
  padding: 40px;
  color: white;
  font-family: "Bell MT", "Courier New CE", "Arial Black", monospace;
}
.qoutename {
  color: yellow;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
}

.learn {
  width: 30%;
  padding: 7px;
  height: 32px;
  margin-left: 0%;
  display: block;
  padding-top: 5px;
  margin-top: 20px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 15px;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
}

.sectionheader {
  color: #086060;
  text-align: center;
  text-transform: uppercase;
}
.whycont {
  width: 100%;
  margin-left: 0%;
  text-align: left;
  margin-bottom: 40px;
  font-size: 15px;
  margin-top: 0px;
  padding: 0px;
  padding-left: 50px;
  padding-right: 50px;
}
.whycont1 {
  margin-top: 20px;
}
.whycont2 {
  margin-bottom: 40px;
}
.mainwhy {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.whyimg {
  width: 60px;
  height: 60px;
  padding: 0px;
  margin-top: 1px;
  margin-right: 20px;
}

.twoskillrow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 10px;
}
.skill {
  display: inline-block;
  width: 42%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 20px;
}
.skillsetcont {
  padding: 40px;
  background-color: #fdfdffef;
  margin-bottom: 40px;
}
.skillname {
  margin-bottom: 0px;
  color: #086060;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
}
.percentage {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.137);
  border-radius: 25px;
  height: 12px;
  opacity: 1;
}
.nopercent {
  float: right;
}
.percentage_guage {
  height: 100%;
  border-radius: 25px;
  background-color: #086060;
}
.webdev {
  width: 0%;
}
.appdev {
  width: 0%;
}
.seo {
  width: 0%;
}
.graphics {
  width: 0%;
}
.ux {
  width: 0%;
}
.de {
  width: 0%;
}
.sl {
  margin-left: 6%;
}
.mainwhat {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: visible;
}

.testimony {
  width: 30%;
  margin-left: 2%;
  margin-right: 2%;
  border: none;
  height: 190px;
  margin-top: 20px;
  color: white;
  text-align: center;
  box-shadow: 4px 4px 4px 4px rgba(255, 255, 255, 0.646);
  display: inline-block;
  text-align: center;
  overflow-y: visible;
}

.msg {
  width: 100%;
  background-color: #086060;
  height: 58%;
  border-radius: 20px;
  padding: 15px;
}

.tl {
  margin-left: 4%;
}

.imgcont {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: white;
  margin-top: -30%;
  display: inline-block;
  border: 3px solid #086060;
}
.testimg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.testname {
  font-size: 14px;
  margin-top: 10px;
  color: #086060;
  font-weight: bold;
}

.figure {
  font-size: 36px;
  font-weight: bold;
}
.marks {
  color: black;
  font-size: 21px;
  text-align: center;
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
}
.mark {
  background-color: white;
  width: 100%;
  height: auto;
  padding: 30px;
  margin-top: 90px;
  display: flex;
  flex-direction: row;
}

.brands {
  width: 100%;
  height: auto;
  padding: 30px;
  display: flex;
  flex-direction: row;
}
.brandscont {
  background-color: white;
  padding-top: 40px;
  padding-bottom: 40px;
}
.brandlogo {
  width: 23%;
  margin-left: 4%;
  margin-right: 4%;
  height: 80px;
}
.bl {
  margin-left: 8%;
}

.pagnition {
  height: 6px;
  width: 20px;
  border-radius: 26%;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #086060;
  display: inline-block;
  opacity: 0.2;
}
.pagnitioncont {
  margin-top: 0px;
  text-align: center;
}
.pag1 {
  opacity: 1;
}

.none {
  display: none;
}
.whyh {
  font-weight: bold;
}
/* .aboutus {
  position: relative;
  border-radius: 50px;
  padding: 5px;
} */

.rm {
  width: 28%;
  padding: 12px;
  height: 50px;
  margin-top: 20px;
  display: block;
  margin-left: 0%;
  background-color: #086060;
  color: white;
  text-align: center;
  font-size: 14px;
  font-size: 19px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
}
/*
.percentage_guage:after{
content:"";
position: relative;
left: 95%;
top: -5px;
width: 0;
height: 0;
border: 6px solid transparent;
border-color:  #086060;
border-radius: 20px;
height: 2px;
margin-right: 0px;
margin-left: 0px;
opacity: 0.8;
}

/*.forbg{background-image: url('../assets/bg2.svg');background-repeat: no-repeat;}*/

.aboutrow {
  height: auto;
  width: inherit;
  background-color: transparent;
  padding: 20px;
}
.aboutimg {
  width: 350px;
  height: 300px;
  display: block;
  margin-left: 40px;
}

.mission_card {
  width: 100%;
  text-align: left;
  color: #373839;
  transition-duration: 0.4s;
  /*border-top-left-radius: 15%;border-bottom-right-radius: 15%;*/
  padding: 20px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 15px;
  padding-top: 10px;
  height: auto;
  margin-bottom: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 1px solid #00000030;
  transition: ease-in 1s;
  cursor: pointer;
}

.card_name {
  font-size: 21px;
  color: #086060;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.mission_card:hover {
  background-color: #086060;
  border: none;
  color: white;
}
.mission_card:hover .card_name {
  color: white;
  transition: ease-in 1s;
}

.servicecard {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 450px;
  width: 90%;
  margin-left: 5%;
  /*margin-right: 15%; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.service {
  margin-bottom: 50px;
}
.service_card_img {
  width: 100%;
  height: 50%;
  /* padding: 20px; */
  cursor: pointer;
  transition: ease-in 5s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.service_card_img:hover {
  object-fit: cover;
  opacity: 0.5;
}

.service_write {
  padding: 10px;
}
.readmore {
  display: block;
  text-align: right;
  margin-top: 10px;
  transition: ease-in 1s;
  cursor: pointer;
}
.readmore:hover {
  color: #086060;
}
.service_row1 {
  margin-top: 50px;
  padding-left: 150px;
  padding-right: 150px;
}
@media screen and (max-width: 480px ) {
  .servicecard {
  height: 470px;
  width: 100%;
margin-left:0%
}
  .aboutrow {
    height: auto;
    border-radius: 4px;
    padding: 4px;
  }

  .resnone {
    display: none;
  }
  .home {
    padding: 0px;
    padding-bottom: 0px;
  }
  .main {
    font-size: 16px;
    color: white;
    text-align: justify;
    padding: 4px;
  }
  .skill {
    display: block;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  .twoskillrow {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .skillsetcont {
    padding: 20px;
  }

 
   .servicecard {margin-bottom:40px}
  /* #1b1d3a #20283d  #01082c*/
  /* .aboutus {
    margin-bottom: 30px;
    padding: 10px;
    background-color: #086060;
    width: 90%;
    margin-left: 5%;
    margin-top: -20px;
  } */
  .about_cont {
    font-size: 16px;
    padding: 5px;
    text-align: center;
    padding-bottom: 20px;
  }
  .qoutename {
    font-size: 15px;
    margin-top: 10px;
  }
  .learn {
    width: 80%;
    padding: 7px;
    height: 32px;
    margin-left: 10%;
    display: block;
    padding-top: 5px;
    margin-top: 20px;
  }

  .sectionheader {
    color: #086060;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }
  .whycont {
    width: 100%;
    margin-left: 0%;
    text-align: left;
    margin-bottom: 40px;
    font-size: 16px;
    padding: 0px;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .whycont1 {
    margin-top: 20px;
  }
  .whycont2 {
    margin-bottom: 40px;
  }
  .mainwhy {
    margin-bottom: 20px;
  }
  .whyimg {
    width: 50px;
    height: 50px;
    padding: 0px;
    margin-top: 15px;
    margin-right: 10px;
  }

  .testimony {
    width: 48%;
    margin-left: 1%;
    margin-right: 2%;
    border: none;
    height: 180px;
    margin-top: 20px;
    color: white;
    text-align: center;
  }
  .msg {
    width: 100%;
    background-color: #086060;
    height: 58%;
    border-radius: 20px;
    padding: 5px;
    font-size: 11px;
    padding-top: 8px;
  }

  .tl {
    margin-left: 4%;
  }

  .imgcont {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: white;
    margin-top: -30%;
    display: inline-block;
    border: 3px solid #086060;
  }
  .testimg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .testname {
    font-size: 13px;
    margin-top: 10px;
    color: #086060;
    font-weight: bold;
  }

  .figure {
    font-size: 32px;
    font-weight: bold;
  }
  .marks {
    color: white;
    font-size: 14px;
    text-align: center;
    width: 23%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .mark {
    background-color: #086060;
    width: 100%;
    height: auto;
    padding: 5px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .brands {
    width: 100%;
    height: auto;
    padding: 5px;
    display: flex;
    flex-direction: row;
  }
  .brandscont {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .brandlogo {
    width: 32%;
    margin-left: 2%;
    margin-right: 2%;
    height: 50px;
  }
  .bl {
    margin-left: 2%;
  }

  .ft {
    margin-top: -100px;
  }
  .figtag {
    color: white;
  }

  .rm {
  width: 40%;}

  .service_row1 {
  /* margin-top: 50px; */
  padding-left: 20px;
  padding-right: 20px;
}
}
</style>
