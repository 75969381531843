<template>
  <div class="resmenu animate__animated animate__slideInLeft none">
    <!-- <span class="limgcont">
 <router-link to="/"><img src="../../assets/logo0.png" alt="" class="logoo  animate__animated animate__zoomIn"></router-link>
 </span> -->

    <!-- <div class="contacts none">
<div class="othercontact ">
<img src="../../assets/wphone.png" alt="" class="contactimg">
<a class="mcontact link" href="tel:09036343095">(+234) 9036343095</a>
</div>

<div class="othercontact">
<img src="../../assets/wemail.png" alt="" class="contactimg">
<a class="mcontact link" href="mailto:info@echelontech.com.ng">info@echelontech.com.ng</a>
</div>

</div> -->

    <div class="mmenucont">
      <router-link to="/home" class="navs df">Home</router-link>
      <!-- <router-link to="/Services" class="navs">Services</router-link>-->
      <div class="ib navwithsub df" v-on:click="showsubnav()">
        Services <i class="fa fa-angle-down adown" id="adown"></i
        ><i class="fa fa-angle-up none" id="aup"></i>
      </div>
      <div class="subnav none">
        <router-link to="/web_development" class="sub_navs"
          >Web development</router-link
        >
        <router-link to="/app_development" class="sub_navs"
          >App development</router-link
        >
        <router-link to="/graphics_designing" class="sub_navs"
          >Grapic Designing</router-link
        >
        <router-link to="/SEO" class="sub_navs">SEO</router-link>
        <router-link to="/digital_marketing" class="sub_navs"
          >Digital Marketing</router-link
        >
        <router-link to="/consultancy" class="sub_navs"
          >Consultancy</router-link
        >
      </div>
      <!-- <div class="navs df">Blog</div> -->

      <router-link to="/Contact" class="navs df">Contact us</router-link>
      <router-link to="/About" class="navs df">About us</router-link>
      <router-link to="/Policy" class="navs df">Company Policy</router-link>
    </div>

    <router-link to="/qoute" class="action_btn rm link text-light"
      >Get a Qoute</router-link
    >
    <br />
    <div class="social">Follow us on</div>
    <div class="socialcont">
      <a href="https://www.facebook.com/profile.php?id=100063963073424"
        ><img src="../../assets/facebook.png" alt="" class="socialimg"
      /></a>
      <a href="https://wa.me/message/6U7GNZJK63M2M1"
        ><img src="../../assets/whatsapp.png" alt="" class="socialimg"
      /></a>
      <a href="https://www.instagram.com/p/CirtrM7LAG7/?igshid=YmMyMTA2M2Y"
        ><img src="../../assets/linkedin.png" alt="" class="socialimg"
      /></a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Resmenu",
  components: {},
  methods: {
    showsubnav: function () {
      $(".subnav").toggle(500);
    },
    hidemenu: function () {
      $(".resmenu").hide(1000);
      //.removeClass("animate__slideInLeft").addClass("animate__slideOutLeft")
    },
  },
  mounted() {
    $(".navs,.sub_navs").click(function () {
      $(".subnav").hide(500);
    });
    $(document).scroll(function () {
      $(".subnav").hide(500);
    });
  },
};
</script>

<style scoped>
.none {
  display: none;
}

@media screen and (max-width: 480px) {
  .faicon {
    color: black;
  }
  .navs,
  .navwithsub {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
    font-size: 17px;
    text-decoration: none;
    color: black;
    display: block;
  }
  .df {
    color: black;
    font-weight: bold;
  }

  .resmenu {
    padding-bottom: 20px;
    width: 50%;
    display: none;
    z-index: 999;
    margin-top: 20px;
    padding: 0px;
    color: black;
    background-color: white;
    position: fixed;
    left: 0%;
    top: 35px;
    height: 100%;
    backdrop-filter: blur(5px);
  }
  .mmenucont {
    margin-top: 30px;
  }
  .rm {
    position: relative;
    background-color: #086060;
    padding: 5px;
    padding-top: 7px;
    margin-top: 30px;
    font-size: 14px;
    width: 80%;
    text-align: center;
    margin-left: 5%;
    margin-bottom: 20px;
    display: block;
    border-radius: 4px;
    font-weight: bold;
  }

  .subnav {
    position: relative;
    /* background-color: #086060; */
    background-color: #fdfdffef;
    margin-top: -15px;
    padding: 14px;
    border-bottom-left-radius: 0px;
    padding-bottom: 4px;
    width: 100%;
    border-bottom-right-radius: 0px;
    padding-left: 0px;
    padding-top: 0px;
  }

  .sub_navs {
    color: black;
    display: block;
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 14px;
    padding: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sub_navs:hover {
    background-color: #086060;
    color: white;
  }

  .othercontact {
    /* color: white */
    font-size: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 15px;
  }
  .contactimg {
    height: 20px;
    width: 20px;
  }
  .mcontact {
    display: inline-block;
    margin-left: 10px;
  }
  .contacts {
    margin-top: 30px;
    width: 100%;
    background-color: #086060;
    padding-top: 15px;
    padding-bottom: 15px; /*box-shadow: 4px 4px 4px 1px rgb(236, 236, 236);background-color: white;*/
  }
  .m {
    margin-top: 40px;
    font-size: 12px;
    width: 90%;
    margin-left: 5%;
  }

  .logoo {
    width: 140px;
    height: 35px;
    margin-top: 10px;
  }

  .limgcont {
    width: 100%;
    display: block;
  }

  .socialcont {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .socialimg {
    height: 35px;
    width: 35px;
    padding: 5px;
    border-radius: 10%;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 4px 4px 4px 1px rgb(236, 236, 236);
    background-color: white;
  }
  .social {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  a,
  a:visited,
  .link:hover {
    text-decoration: none;
  }

  .subnav:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0%;
    width: 0;
    height: 0;
    border: 0px solid transparent;
    border-bottom-color: transparent;
    margin-top: -26px;
    margin-left: 0px;
  }

  .router-link-active {
    color: #086060;
    font-weight: bolder;
  }
  /* .drop{transform:rotate(180deg);display: inline-block;margin-top: -58px;margin-left: 2px;font-size: 20px;}<span class="drop drop2">^</span> */
}
</style>
