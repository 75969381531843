<template>
  <div class="cont">
    <Header />

    <div class="home">
      <h4 class="sectionheader animate__animated animate__zoomIn">Service</h4>

      <div class="service_row1 row" data-aos="zoom-in">
            <!--service cont -->
            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/web developmnet.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Web development</h5>
                  <div class="service_about">
                    Our goal is to to optimize user‘s experience. we are
                    commited to helping you grow your business.

                    <span class="readmore"
                      ><router-link to="/web_development" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/mobile app.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Mobile app development</h5>
                  <div class="service_about">
                    We have team of developers with,proficiency and skilled
                    expertise as well as experience in android application
                    Development.
                    <span class="readmore"
                      ><router-link to="/app_development" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/digital marketing.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Digital marketing</h5>
                  <div class="service_about">
                    We generate demand for your Business, So You Could Continue
                    What You Do The Best!
                    <span class="readmore"
                      ><router-link to="/digital_marketing" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- second service card -->

          <div class="service_row1 row" data-aos="zoom-in">
            <!--service cont -->
            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/consult.svg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Consultancy</h5>
                  <div class="service_about">
                    Echelon Tech LLC is a business consultant focused on growth
                    strategy and implementation.
                    <span class="readmore"
                      ><router-link to="/consultancy" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img
                  src="../assets/graphic design.jpg"
                  alt=""
                  class="service_card_img"
                />
                <div class="service_write">
                  <h5 class="service_head">Graphics Designing</h5>
                  <div class="service_about">
                    Beautiful hand-made professional graphic design for
                    websites, branding and print materials.
                    <span class="readmore"
                      ><router-link to="/graphics_designing" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card servicecard">
                <img src="../assets/seo.jpg" alt="" class="service_card_img" />
                <div class="service_write">
                  <h5 class="service_head">Search Engine Optimization</h5>
                  <div class="service_about">
                    we are a strategic digital Marketing agency powered by data
                    analytics, market research, and digital technologies.
                    <span class="readmore"
                      ><router-link to="/SEO" class="text-dark">
                        Read More
                        <span
                          class="fa fa-angle-double-right"
                        ></span></router-link
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      <!--- row end-->
    </div>

    <Resmenu />

    <Footer />
  </div>
</template>

<script>
//import $ from "jquery"

import Header from "./plugins/header.vue";
import Footer from "./plugins/Footer.vue";
import Resmenu from "./plugins/rmenu.vue";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
body {
  overflow: hidden;
}
.sectionheader {
  color: #086060;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
}

.home {
  padding: 20px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 120px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}
.imgcont {
  text-align: center;
}
.servicecard {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 380px;
  width: 90%;
  margin-left: 5%;
  /*margin-right: 15%; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.service {
  margin-bottom: 50px;
}
.service_card_img {
  width: 100%;
  height: 50%;
  /* padding: 20px; */
  cursor: pointer;
  transition: ease-in 5s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.service_card_img:hover {
  object-fit: cover;
  opacity: 0.5;
}

.service_write {
  padding: 10px;
}
.readmore {
  display: block;
  text-align: right;
  margin-top: 10px;
  transition: ease-in 1s;
  cursor: pointer;
}
.readmore:hover {
  color: #086060;
}
.service_row1 {
  margin-top: 50px;
  padding-left: 150px;
  padding-right: 150px;
}
.row {
  margin-bottom: 40px;
}

@media screen and (max-width: 480px) {
  .home {
    padding: 0px;
    padding-bottom: 0px;
    margin-top: 80px;
  }
  .main {
    font-size: 13px;
    color: white;
    text-align: justify;
    padding: 4px;
  }
  .rm {
  width: 32%;}

  .service_row1 {
  /* margin-top: 50px; */
  padding-left: 20px;
  padding-right: 20px;
}
   .servicecard {margin-bottom:40px}

}
</style>
