<template>
  <div class="cont">
    <Header />

    <div class="home">
      <div class="row row1">
        <div class="row imgcont">
          <img src="../../assets/ss.jpg" class="servicecardimg" />
        </div>

        <div class="writeup">
          <div class="writeupinw">
            <h4 class="sectionheader animate__animated animate__zoomIn">
              Consultancy
            </h4>
            <router-link to="/qoute" class="learn">GET A QOUTE </router-link>
          </div>
        </div>
        <!--- row end-->

        <div class="row row2 write">
          <div data-aos="slide-up">
            Echelon Tech LLC is a business consultant focused on growth strategy
            and implementation. We enable clients to dream their wildest dreams.
            To Imagine their greatest success. To deliver on their ultimate
            vision. If you can Imagine your desired future, we can help you make
            it a reality. We bring clarity to your business and help it grow.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">
              BEGINS WITH TRUST, ENDS WITH RESULTS
            </h4>
            For each engagement, we build a team who has the practical
            experience, education, and technical skills to best tackle your
            project and find the solutions you are looking for. This process
            allows us to tailor our business consulting services and create a
            custom strategy just for you – ensuring we achieve the best possible
            results for all our clients.Choosing the right strategic management
            consultant can make all the difference. It appears as if we can do
            everything for everybody – how can we do this? We draw from senior
            expertise from contracted professionals and Subject Matter Experts
            (SMEs) for support in areas that we do not internally have that
            expertise. They can be a great addition to a project team, and their
            support allows TPG’s corporate strategy consulting to really reach
            its full potential as one of the top boutique consulting firms in
            Umuahia.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">STRATEGIC PLANNING</h4>
            As one of the leading marketing companies in Umuahia, we know about
            marketing strategy, planning and implementation. Marketing is all
            about positioning and differentiation which leads you to becoming
            noticed. Need to pivot or find new products, services or markets?
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">MARKETING PLAN</h4>
            Website design built upon UX strategy that responds to its
            environment and user behaviour offering an optimized UI design and
            browsing experience for all of today’s common devices.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">ORGANIZATIONAL DESIGN</h4>
            Organizational design and structure follows your company’s vision,
            mission and strategy and it is important that your organizational
            structure is in line with your current strategy. We work closely
            with your team to establish the requisite organization for your
            company’s success and mitigate the risks of your company flaming out
            due to poor results and insufficient growth.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">WHAT WE DO</h4>
            Echelon Tech LLC is a business consultant focused on growth strategy
            and implementation. We enable clients to dream their wildest dreams.
            To Imagine their greatest success. To deliver on their ultimate
            vision. If you can Imagine your desired future, we can help you make
            it a reality. We bring clarity to your business and help it grow.
            Our services are comprehensive and fully customizable to your needs.
            They include, but are not limited to strategic planning, marketing,
            financial, organizational design and coaching. This includes
            consulting and implementation services in operational disciplines
            such as marketing strategy, marketing planning, marketing services,
            marketing audit, digital and online marketing, competitive and
            market intelligence,business consulting and strategic planning
            services, organizational design and structure, financial consulting
            and financial management. We deliver these services by different
            means: consulting, hands-on implementation, coaching, advisory
            board, project manager, or an interim management team. You decide
            what works best for you and we deliver to meet your needs.
          </div>
        </div>
      </div>
      <!--- home end-->
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from ".././plugins/header.vue";
import Footer from ".././plugins/Footer.vue";
import Resmenu from ".././plugins/rmenu.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}
body {
  overflow: hidden;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
  padding: 0px;
}

.sectionheader {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
}

.home {
  padding: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 80px;
  padding-top: 0px;
  font-size: 18px;
}
.servicecardimg {
  width: 100%;
  height: 100%;
}
.imgcont {
  padding-right: 0px;
  text-align: center;
  width: 100%;
  height: 650px;
  margin-top: 20px;
}
.writeup {
  position: absolute;
  top: 75px;
  left: 0px;
  height: 654px;
  background-color: #086060ad;
  z-index: 2;
  width: 100%;
}

.writeupinw {
  width: 100%;
  text-align: center;
  padding-top: 250px;
  color: white;
}

.learn {
  width: 20%;
  padding: 10px;
  height: 40px;
  margin-left: 40%;
  display: block;
  padding-top: 5px;
  margin-top: 70px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .servicecardimg {
    margin-left: 0%;
  }
  #app {
    overflow-x: visible;
  }


  .sectionheader {
    margin-bottom: 20px;
    font-size: 19px;
    margin-top: -25%;
  }
  .main {
    font-size: 15px;
    color: white;
    text-align: justify;
    padding: 0px;
  }
  .write {
    padding: 0px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
    width: 100%;
    padding-left: 20px;
  }
  .imgcont {
    padding-right: 0px;
    width: 100%;
    height: 320px;
    padding: 0px;
  }
  .writeup {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 320px;
  }
.home {margin-top: 40px;}
.writeupinw {
  padding-top: 180px;
}
  .learn {
    width: 60%;
    padding: 7px;
    height: 30px;
    margin-left: 20%;
    display: block;
    padding-top: 4px;
    margin-top: 30px;
    background-color: white;
    color: #086060;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    font-weight: bold;
  }
    .ft {
    margin-top: -220px;
  }
}
</style>
