<template>
  <div class="cont">
    <Header />

    <div class="home">
      <div class="row row1">
        <div class="row imgcont">
          <img src="../../assets/ss.jpg" class="servicecardimg" />
        </div>

        <div class="writeup">
          <div class="writeupinw">
            <h4 class="sectionheader animate__animated animate__zoomIn">
              Web development
            </h4>
            <router-link to="/qoute" class="learn">GET A QOUTE </router-link>
          </div>
        </div>
        <!--- row end-->

        <div class="row row2 write">
          <div data-aos="slide-up">
            Website design should be one of the rudiments of any business
            seeking online success. A great website should be able to create
            that sensation, an ever-lasting impression. Our skilled website
            designers create outstanding, well-structured and organized websites
            that ensure a great customer experience. We create exceptional
            Website Design with bunch of features to make your website stand out
            of crowd for your startup or existing business.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">WEB DESIGN THAT MAKES AN IMPACT</h4>
            Our web design firm has two very simple goals; to optimize every
            user‘s experience and hand-craft measurable impact for all our
            clients. commitment to helping you grow your business.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">WEBSITE DESIGNING</h4>
            Engaging web design built upon user experience strategy that
            responds to its environment and user behaviour offering an optimized
            UI design and browsing experience for all devices.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">WORDPRESS DESIGNING</h4>
            Website design built upon UX strategy that responds to its
            environment and user behaviour offering an optimized UI design and
            browsing experience for all of today’s common devices.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">ECOMMERCE DEVELOPMENT</h4>
            Whether it’s WordPress or any other Open Source platform, our web
            design and development services are backed by robust e-Commerce
            development to support small business growth.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">
              A FRESH AND USER-FRIENDLY WEBSITE DESIGN THAT IS CUSTOMIZED FOR
              YOUR COMPANY AND CATERS TO YOUR CLIENTS.
            </h4>
            We focuses on website designs for business-to-business (B2B) and
            business-to-clients (B2C) companies that are driven by results. We
            provide a personalized and creative solution that will reflect your
            company’s brand, values and corporate image effectively. Our
            solution ultimately helps to showcase your company’s profile,
            promotes products and services, and drives more customers to your
            business. Our website design solutions are responsive, Google
            friendly, securely hosted, easily accessible and are consistent with
            regular colourful content.
          </div>
        </div>
      </div>
      <!--- home end-->
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from ".././plugins/header.vue";
import Footer from ".././plugins/Footer.vue";
import Resmenu from ".././plugins/rmenu.vue";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}

body {
  overflow: hidden;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
  padding: 0px;
}

.sectionheader {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
}

.home {
  padding: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 80px;
  padding-top: 0px;
  font-size: 18px;
}
.servicecardimg {
  width: 100%;
  height: 100%;
}
.imgcont {
  padding-right: 0px;
  text-align: center;
  width: 100%;
  height: 650px;
  margin-top: 20px;
}
.writeup {
  position: absolute;
  top: 75px;
  left: 0px;
  height: 654px;
  background-color: #086060ad;
  z-index: 2;
  width: 100%;
}

.writeupinw {
  width: 100%;
  text-align: center;
  padding-top: 250px;
  color: white;
}

.learn {
  width: 20%;
  padding: 10px;
  height: 40px;
  margin-left: 40%;
  display: block;
  padding-top: 5px;
  margin-top: 70px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .servicecardimg {
    margin-left: 0%;
  }
  #app {
    overflow-x: visible;
  }


  .sectionheader {
    margin-bottom: 20px;
    font-size: 19px;
    margin-top: -25%;
  }
  .main {
    font-size: 15px;
    color: white;
    text-align: justify;
    padding: 0px;
  }
  .write {
    padding: 0px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
    width: 100%;
    padding-left: 20px;
  }
  .imgcont {
    padding-right: 0px;
    width: 100%;
    height: 320px;
    padding: 0px;
  }
  .writeup {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 320px;
  }
.home {margin-top: 40px;}
.writeupinw {
  padding-top: 180px;
}
  .learn {
    width: 60%;
    padding: 7px;
    height: 30px;
    margin-left: 20%;
    display: block;
    padding-top: 4px;
    margin-top: 30px;
    background-color: white;
    color: #086060;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    font-weight: bold;
  }

    .ft {
    margin-top: -220px;
  }

.home {margin-top: 40px;}
}
</style>
