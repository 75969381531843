<template>
  <div class="cont">
    <Header />

    <div class="home">
      <h4 class="sectionheader">About Echelon Tech</h4>

      <div class="row row2 write animate__animated animate__zoomIn">
        Echelon Tech is a dynamic and forward-thinking technology firm dedicated
        to pioneering innovation and driving positive change in the digital
        landscape. Established with a vision to revolutionize the way businesses
        operate and individuals interact with technology, Echelon Tech has
        evolved into a trusted partner for organizations seeking to leverage the
        power of cutting-edge solutions. At Echelon Tech, our values serve as
        the guiding principles that shape our culture, drive our actions, and
        define who we are as a company.
      </div>

      <div
        class="row row2 write animate__animated animate__zoomIn"
        data-aos="zoom-in"
      >
        <h4 class="sectionheadersub">Our Values</h4>
        1. Innovation: We believe in the power of innovation to transform
        industries, disrupt the status quo, and unlock new possibilities. We are
        committed to pushing the boundaries of what is possible and continually
        seeking out new and innovative solutions to meet the evolving needs of
        our clients. <br />2. Excellence: We are dedicated to excellence in
        everything we do. From the quality of our products and services to the
        relationships we build with our clients and partners, we strive to
        exceed expectations and deliver results that are second to none.<br />
        3. Integrity: We operate with honesty, transparency, and integrity in
        all aspects of our business. We believe that trust is the foundation of
        every successful partnership, and we are committed to upholding the
        highest ethical standards in everything we do.<br />
        4. Collaboration: We believe in the power of collaboration to drive
        innovation and foster creativity. We work closely with our clients,
        partners, and team members to develop solutions that solve complex
        problems and deliver real value.<br />
        5. Diversity and Inclusion: We celebrate diversity and embrace the
        unique perspectives and talents of all individuals. We believe that
        diversity drives innovation and creativity, and we are committed to
        creating an inclusive work environment where everyone feels valued,
        respected, and empowered to contribute their best.
      </div>

      <div class="row row2 write" data-aos="slide-up">
        <h4 class="sectionheadersub animate__animated animate__zoomIn">
          Vision Statement
        </h4>
        Our vision is to be a global leader in technology innovation, empowering
        businesses and individuals to achieve their full potential through
        advanced and sustainable solutions. We aspire to create a world where
        technology serves as a catalyst for positive change, driving progress,
        prosperity, and inclusivity for all.
      </div>

      <div class="row row2 write" data-aos="slide-up">
        <h4 class="sectionheadersub animate__animated animate__zoomIn">
          Mission Statement
        </h4>
        At Echelon Tech, our mission is to harness the power of technology to
        solve real-world problems, enhance productivity, and improve the quality
        of life for our customers. We are committed to delivering exceptional
        service, fostering a culture of continuous improvement, and maintaining
        the highest standards of integrity and excellence in everything we do.
        Through our relentless pursuit of innovation and our unwavering
        dedication to customer success, we strive to be the partner of choice
        for organizations seeking to thrive in the digital age.
      </div>
    </div>
    <!--- home end-->

    <Resmenu />

    <Footer class="ft" />
  </div>
</template>

<script>
//import $ from "jquery"

import Header from "./plugins/header.vue";
import Footer from "./plugins/Footer.vue";
import Resmenu from "./plugins/rmenu.vue";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft {
  margin-top: -170px;
}

body {
  overflow: hidden;
}
.sectionheader {
  color: #086060;
  text-align:justify;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
}
.sectionheadersub {
  color: #086060;
  text-align:justify;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 20px;
}

.home {
  padding: 50px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 90px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
  font-size: 18px;
}
.write {
  padding: 30px;
  padding-top: 0px;
  font-size: 18px;
  text-align:justify;
}
.sectionheadersub {
  margin-left: -1%;
}
.sectionheader {
  margin-left: 21px;
}
@media screen and (max-width: 480px) {
  .home {
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 80px;
  }
  .sectionheader {
    margin-bottom: 20px;
  }
  .main {
    font-size: 13px;
    color: white;
    text-align: justify;
    padding: 4px;
  }
  .write {
    padding: 10px;
    padding-top: 0px;
    font-size: 14px;
    text-align:justify;
  }
  .sectionheadersub,
  .sectionheader {
    margin-left: -1%;
  }
}
</style>
