<template>
  <div class="cont">
    <Header />

    <div class="home">
      <div class="row row1 none">
        <div class="row imgcont">
          <img src="../assets/ss.jpg" class="servicecardimg" />
        </div>
        <div class="writeup">
          <div class="writeupinw">
            <h4 class="sectionheader animate__animated animate__zoomIn">
              Qoute
            </h4>
          </div>
        </div>
        <!--- row end-->
      </div>
      <!--- home end-->

      <div class="row row3 qoute" data-aos="slide-up">
        <h4 class="sectionheader text-light">Get a qoute</h4>
        <br />
        <div class="inputrow">
          <div class="inputcont animate__animated animate__zoomIn">
            <div class="label">Name</div>
            <!-- <input type="text" placeholder="Your Name" class="in"> -->
            <input type="text" placeholder="" class="in" />
          </div>

          <div class="inputcont animate__animated animate__zoomIn">
            <div class="label">Email</div>
            <!-- <input type="email" placeholder="" class="in"> -->
            <input type="email" placeholder="" class="in" />
          </div>
        </div>

        <div class="inputrow">
          <div class="inputcont animate__animated animate__zoomIn special_edit">
            <div class="projcont ib">
              <div class="label">Project name</div>
              <input type="email" placeholder="" class="in" />
            </div>

            <div class="budgetcont ib">
              <div class="label">Budget ($)</div>
              <input type="email" placeholder="" class="in" />
            </div>
          </div>

          <div class="inputcont animate__animated animate__zoomIn selectcont">
            <div class="label">Select Project</div>
            <select name="projects" id="">
              <option value="web development" disabled>Select a project</option>
              <option value="web development">Web development</option>
              <option value="web development">Mobile app development</option>
              <option value="web development">Graphics Designing</option>
              <option value="web development">
                Search Engine Optimization
              </option>
              <option value="web development">Wordpress development</option>
              <option value="web development">E-commerce development</option>
              <option value="web development">Web maintanace</option>
              <option value="web development">Database designing</option>
              <option value="web development" disabled>Others</option>

            </select>
          </div>
        </div>

        <div class="inputrow lastinrow">
          <div class="inputcont animate__animated animate__zoomIn manicont">
            <div class="label">Project description</div>
            <textarea name="" placeholder="" class="textarea"></textarea>
          </div>

          <div class="inputcont animate__animated animate__zoomIn manicont">
            <div class="learn">
              SUBMIT QOUTE
            </div>
          </div>
        </div>

        <marquee class="m"
          >For more information or support contact us with
          <b>(+234) 9036343095 - (+234) 9033795944</b></marquee
        >
      </div>
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from "./plugins/header.vue";
import Footer from "./plugins/Footer.vue";
import Resmenu from "./plugins/rmenu.vue";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -80px;}

body {
  overflow: hidden;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
  padding: 0px;
}

.sectionheader {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
  margin-top: 50px;
}

.home {
  padding: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 120px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.qoute {
  background-color: #086060;
  padding: 40px;
  margin-top: -40px;
}
.inputrow {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
.inputcont {
  width: 37%;
  margin-right: 0%;
  margin-left: 8%;
}
.in {
  width: 100%;
  background-color: transparent;
  border: 2px solid white;
  outline: none;
  border-radius: 5px;
  height: 40px;
  color: white;
  padding-left: 10px;
}
select {
  height: 40px;
  width: 100%;
  background: white;
}
.label {
  color: white;
  font-size: 18px;
}
.textarea {
  height: 140px;
  width: 100%;
  border: 2px solid white;
  background-color: transparent;
  border-radius: 5px;
  outline: none;
  color: white;
  padding-left: 10px;
}
.m {
  width: 80%;
  text-align: center;
  color: white;
  margin-left: 80px;
  font-size: 14px;
  font-weight: bold;
}

.learn {
  width: 40%;
  padding: 7px;
  height: 40px;
  margin-left: 0%;
  display: block;
  padding-top: 11px;
  margin-top: 70px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.projcont {
  width: 60%;
}
.budgetcont {
  width: 36%;
  margin-left: 2%;
  float: right;
}
.ib {
  display: inline-block;
}
@media screen and (max-width: 480px) {
  .servicecardimg {
    margin-left: -15%;
  }

  .home {
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 70px;
    padding-left: 0px;
  }
  .sectionheader {
    margin-bottom: 20px;
    font-size: 24px;
    margin-top: 10px;
  }
  .main {
    font-size: 15px;
    color: white;
    text-align: justify;
    padding: 0px;
  }
  .write {
    padding: 0px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
    width: 100%;
  }
  .imgcont {
    padding-right: 0px;
    width: 100%;
    height: 320px;
  }
  .writeup {
    position: absolute;
    top: 80px;
    left: 0px;
    height: 320px;
  }

  .qoute {
    background-color: #086060;
    padding: 5px;
    margin-top: -40px;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .inputrow {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .inputcont {
    width: 48%;
    margin-right: 0%;
    margin-left: 1%;
  }
  .in {
    border-radius: 5px;
    height: 30px;
    color: white;
    padding-left: 5px;
    font-size: 12px;
  }
  select {
    height: 30px;
    background-color: white;
    border: none;
    border-radius: 5px;
  }
  .label {
    color: white;
    font-size: 15px;
  }
  .textarea {
    height: 100px;
    width: 100%;
    display: block;
    border: 2px solid white;
    background-color: transparent;
    border-radius: 5px;
    color: white;
    padding-left: 5px;
  }
  .lastinrow,
  .manicont {
    display: block;
    width: 100%;
  }
  .m {
    width: 95%;
    text-align: center;
    color: white;
    margin-left: 2.5%;
  }

  .learn {
    width: 60%;
    padding: 7px;
    height: 30px;
    margin-left: 20%;
    display: block;
    padding-top: 4px;
    margin-top: 30px;
    background-color: white;
    color: #086060;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    font-weight: bold;
  }

  .special_edit {
    display: block;
    width: 200%;
  }
  .selectcont {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
input{color:white}
</style>
