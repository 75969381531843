<template>
  <div class="cont">
    <Header />

    <div class="home">
      <div class="row row1">
        <div class="row imgcont">
          <img src="../../assets/ss.jpg" class="servicecardimg" />
        </div>

        <div class="writeup">
          <div class="writeupinw">
            <h4 class="sectionheader animate__animated animate__zoomIn">
              Search Engine Optimization
            </h4>
            <router-link to="/qoute" class="learn">GET A QOUTE </router-link>
          </div>
        </div>
        <!--- row end-->

        <div class="row row2 write">
          <div data-aos="slide-up">
            Echelon Tech LLC is a strategic digital Marketing agency powered by
            data analytics, market research, and digital technologies. You’ve
            come to the right place. We’ve partnered with hundreds of small,
            medium, and enterprise businesses to get the best results.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">IS YOUR WEBSITE SEARCH FRIENDLY?</h4>
            When your rankings improve with our Echelon tech SEO company, you’ll
            begin to see more leads that will strengthen your business. Our
            process gets results, which is why clients both old and new see
            consistent lead generation, better rankings, and site traffic
            increases. Ultimately, our numbers speak for themselves while
            brilliantly showcasing our brand’s expertise, performance and
            results. Learn what makes us different and what to expect from
            expert SEO services today.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">DISCOVERY & ANALYSIS</h4>
            Find out who you are, what your business is all about and how we are
            going to make you successful. We have an initial onboard meeting
            where you ask you a specific set of questions to start us off in the
            direction towards success.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">RESEARCH & PLANNING</h4>
            We will find out what your customers are searching when they are
            seeking your products or services and how we are going to get your
            business in front of their eyes online. The main areas of focus here
            is the Keywords, Site Audit and Links.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">OFFSITE OPTIMIZATION</h4>
            We scour the Internet and find every online footprint your business
            has left behind. This is important because we have to make sure
            everything is perfect in terms of following Google's rules. If we
            find any mistakes we fix them for you. This process can sometimes be
            a long but we know exactly what to do from here.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">ONSITE OPTIMIZATION</h4>
            In order for our SEO to be a success your website first needs to be
            ready for both search engines to like it and for visitors to want to
            stay on it. Our experts know exactly what to do with your site.We
            tweak your website code and website content to optimize your
            website.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">CAMPAIGN LAUNCH</h4>
            We provide detailed monthly reporting that shows your progress on
            your rankings, web traffic and the amount of leads you are getting
            every month. Not enough? We also follow up with you on a monthly
            basis to chat ideas, progress and success planning.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">SUCCESS TRACKING</h4>
            Launching your campaign is kind of like launching multiple campaigns
            in one. We have teams that build your links, write your blogs, post
            to your social profiles and manage your online advertising campaigns
            and they all help in achieving your SEO goals and improve your
            search engine rankings.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">
              BEING FOUND ONLINE IS AS IMPORTANT AS A PHONE NUMBER
            </h4>
            SEO marketing is a bit different than other traditional methods. We
            focus on highlighting our clients’ online presence and generating
            organic traffic that gets people talking. And with shoppers moving
            online, more people rely on search engines to provide relevant,
            helpful and informative content. As a result, SEO is now one of the
            most effective tools for businesses looking to grow online. Working
            with Echelon Tech LLC gives you access to our SEO experts, who use
            their knowledge and skills to transform your website into an
            engaging, effortless and exciting storefront, and raise awareness
            about your brand through several key SEO methods, such as: Keyword
            research, analysis & identification, Website structuring, Backlink
            building, Cross-linking, Producing well-written content and AdWords
            campaigns.
          </div>
        </div>
      </div>
      <!--- home end-->
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from ".././plugins/header.vue";
import Footer from ".././plugins/Footer.vue";
import Resmenu from ".././plugins/rmenu.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}
body {
  overflow: hidden;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
  padding: 0px;
}

.sectionheader {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
}

.home {
  padding: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 80px;
  padding-top: 0px;
  font-size: 18px;
}
.servicecardimg {
  width: 100%;
  height: 100%;
}
.imgcont {
  padding-right: 0px;
  text-align: center;
  width: 100%;
  height: 650px;
  margin-top: 20px;
}
.writeup {
  position: absolute;
  top: 75px;
  left: 0px;
  height: 654px;
  background-color: #086060ad;
  z-index: 2;
  width: 100%;
}

.writeupinw {
  width: 100%;
  text-align: center;
  padding-top: 250px;
  color: white;
}

.learn {
  width: 20%;
  padding: 10px;
  height: 40px;
  margin-left: 40%;
  display: block;
  padding-top: 5px;
  margin-top: 70px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .servicecardimg {
    margin-left: 0%;
  }
  #app {
    overflow-x: visible;
  }


  .sectionheader {
    margin-bottom: 20px;
    font-size: 19px;
    margin-top: -25%;
  }
  .main {
    font-size: 15px;
    color: white;
    text-align: justify;
    padding: 0px;
  }
  .write {
    padding: 0px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
    width: 100%;
    padding-left: 20px;
  }
  .imgcont {
    padding-right: 0px;
    width: 100%;
    height: 320px;
    padding: 0px;
  }
  .writeup {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 320px;
  }
.home {margin-top: 40px;}
.writeupinw {
  padding-top: 180px;
}
  .learn {
    width: 60%;
    padding: 7px;
    height: 30px;
    margin-left: 20%;
    display: block;
    padding-top: 4px;
    margin-top: 30px;
    background-color: white;
    color: #086060;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    font-weight: bold;
  }
    .ft {
    margin-top: -220px;
  }
}
</style>
