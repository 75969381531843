<template>
  <div class="footer">
    <div class="row frow1" hidden>
      <!-- <img src="../../assets/logo.png" alt="" class="flogoo">  -->
      <div class="footname">Echelon Tech</div>
      <div class="fwrite">
        EchelonTech LLC is here with a vision to build high quality and
        cost-effective softwares using exceptional technologies and ensuring
        quality and reliability. Our range of solutions varies from Web
        Development, Software/App Development, Graphics, IT Consultancy, SEO &
        SMM services to online marketing and branding.
      </div>
    </div>

    <div class="row frow2">
      <div class="col-md-5" data-aos="slide-left">
        <div class="fnav">
          <div class="navhead">What we offer</div>

          <div class="fnavso">
            <router-link to="/web_development" class="whatweofferfoot">
              Web development</router-link
            >
            <router-link to="/app_development" class="whatweofferfoot">
              App development</router-link
            >
          </div>
          <div class="fnavso">
            <router-link to="/SEO" class="whatweofferfoot"> SEO</router-link>
            <router-link to="/graphics_designing" class="whatweofferfoot">
              Graphic design and branding</router-link
            >
          </div>
          <div class="fnavso">
            <router-link to="/digital_marketing" class="whatweofferfoot">
              Digital Marketing</router-link
            >
            <router-link to="/consultancy" class="whatweofferfoot">
              Consultancy</router-link
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 c">
        <div class="navhead">Information</div>

        <div class="fnavso cnavf">
          <router-link to="/About" class="whatweofferfoot navf"
            >About us</router-link
          >
          <router-link to="/Contact" class="whatweofferfoot navf"
            >Contact us</router-link
          >
        </div>

        <div class="fnavso cnavf">
          <!-- <router-link to="/services" class="whatweofferfoot navf">Our services</router-link> -->
          <router-link to="/Terms and conditions" class="whatweofferfoot navf"
            >Our T & C</router-link
          >
          <router-link to="/policy" class="whatweofferfoot navf"
            >Company Policy</router-link
          >
        </div>
        <div class="fnavso cnavf">
          <!-- <router-link to="/Terms and conditions" class="whatweofferfoot navf">Our T & C</router-link> -->
        </div>
      </div>

      <div class="col-md-3 c">
        <div class="navhead">SAAS projects</div>

        <!-- <div class="fnavso">Loom voice</div> -->
        <div class="fnavso">Scratch Card Generator</div>
      </div>
    </div>

    <!-- <br /> -->
    <div class="copy">
      All Rights Reserved <b>© 2020 - {{ new Date().getFullYear() }}</b> Echelon
      Tech LLC.
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "Footer",
  components: {
    //Footer,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style>
.flogoo {
  width: 430px;
  height: 105px;
  margin-top: -40px;
  margin-left: -100px;
  margin-bottom: -50px;
}

.footer {
  /* background-color: black; */
  padding: 20px;
  padding-bottom: 0px;
  color: white;
  overflow: hidden;
  overflow-x: hidden;
  margin-bottom: -50px;
  padding-top: 240px;
  /* padding-top: 260px; */
  margin-top: 10px;
  background-image: url("../../assets/footer_wave.svg");
  background-repeat: no-repeat;

  background-size: cover;
  background-position: 0% 0%;
  /* background-position: 50% 110%; */
}

.frow1 {
  text-align: center;
  margin-bottom: 0px;
  padding: 40px;
  padding-top: 20px;
  padding-left: 40px;
}

.navhead {
  font-size: 20px;
  margin-bottom: 10px;
  color: yellow;
  font-weight: bolder;
}

.fnav {
  margin-left: 40px;
}
.fn2 {
  margin-left: 100px;
}
.copy {
  font-size: 14px;
  color: white;
  text-align: center;
  width: 100%;
  background-color: #086060;
}
.router-link-active {
  color: white;
  font-weight: bold;
}

.none {
  display: none;
}
.router-link,
a {
  color: white;
  text-decoration: none;
  font-weight: bolder;
}

.whatweofferfoot {
  font-size: 14px;
  display: inline-block;
  margin-right: 28px;
}
.fnavso {
  display: block;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: lighter;
  text-decoration: none;
  color: white;
}
.fwrite {
  text-align: left;
  width: 90%;
  margin-left: 0%;
  margin-top: 20px;
}
.footname {
  font-size: 34px;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.router-link:hover,
.whatweofferfoot:hover {
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .flogoo {
    width: 600px;
    height: 80px;
    margin-top: -30px;
    margin-left: -70px;
    margin-bottom: -20px;
  }

  .navhead {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .resnone {
    display: none;
  }
  .c {
    display: inline-block;
    width: 45%;
  }
  .fnav {
    margin-left: 0px;
  }
  .fn2 {
    margin-left: 0px;
  }
  #footer {
    background-color: #2d2c36;
    padding: 0px;
    padding-bottom: 0px;
  }
  .frow1 {
    padding: 0px;
    margin-bottom: 10px;
  }
  .copy {
    padding-left: 0px;
    text-align: center;
    padding: 0px;
    font-size: 10px;
  }

  .whatweofferfoot {
    font-size: 14px;
    display: inline-block;
    margin-right: 12px;
  }
  .cnavf {
    margin-bottom: 0px;
  }
  .navf {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .fwrite {
    text-align: left;
    width: 100%;
    margin-left: 0%;
    font-size: 13px;
    display: block;
    margin-top: 10px;
  }
  .fnavso {
    color: white;
  }
  .footname {
    font-size: 20px;
  }

  .footer {
    margin-top: -180px;
  }
}
</style>
