<template>
  <div class="cont">
    <Header />

    <div class="home">
      <div class="row row1">
        <div class="row imgcont">
          <img src="../../assets/ss.jpg" class="servicecardimg" />
        </div>

        <div class="writeup">
          <div class="writeupinw">
            <h4 class="sectionheader animate__animated animate__zoomIn">
              MOBILE APP DEVELOPMENT
            </h4>
            <router-link to="/qoute" class="learn">GET A QOUTE </router-link>
          </div>
        </div>
        <!--- row end-->

        <div class="row row2 write">
          <div data-aos="slide-up">
            Our committed team of developers has intense knowldege,proficiency
            and skilled expertise as well as experience in android application
            Development. Apps developed by us can be smoothly and consistently
            adappted and integrated across multiple android versions and on
            various Android smartphons and devices. Our customized Android
            Application Development services has a wide range of variety of
            domains right from e-commernce to multimedia applications,business
            applications to health & fitness apps,news and magazines apps to
            shopping apps,and much more.
            <br />
            OUR MOBILE APP SERVICES INCLUDE:
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">ANDROID APP DESIGN</h4>
            Design and user experience can make or break your app. Our Android
            app developers ensure excellent designs.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">ANDROID APP DEVELOPMENT</h4>
            We have experts Android app developers working on Android Studio
            with average experience of 5+ years.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">ENTERPRISE ANDROID APPS</h4>
            We have experts Android app developers working on enterprise android
            apps for fortune 10+ clients.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">GAME DEVELOPMENT</h4>
            We have android game developers to provide you high performance and
            100% reliable android gaming services.
          </div>
        </div>
      </div>
      <!--- home end-->
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from ".././plugins/header.vue";
import Footer from ".././plugins/Footer.vue";
import Resmenu from ".././plugins/rmenu.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}
body {
  overflow: hidden;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
  padding: 0px;
}

.sectionheader {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
}

.home {
  padding: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 80px;
  padding-top: 0px;
  font-size: 18px;
}
.servicecardimg {
  width: 100%;
  height: 100%;
}
.imgcont {
  padding-right: 0px;
  text-align: center;
  width: 100%;
  height: 650px;
  margin-top: 20px;
}
.writeup {
  position: absolute;
  top: 75px;
  left: 0px;
  height: 654px;
  background-color: #086060ad;
  z-index: 2;
  width: 100%;
}

.writeupinw {
  width: 100%;
  text-align: center;
  padding-top: 250px;
  color: white;
}

.learn {
  width: 20%;
  padding: 10px;
  height: 40px;
  margin-left: 40%;
  display: block;
  padding-top: 5px;
  margin-top: 70px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .servicecardimg {
    margin-left: 0%;
  }
  #app {
    overflow-x: visible;
  }


  .sectionheader {
    margin-bottom: 20px;
    font-size: 19px;
    margin-top: -25%;
  }
  .main {
    font-size: 15px;
    color: white;
    text-align: justify;
    padding: 0px;
  }
  .write {
    padding: 0px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
    width: 100%;
    padding-left: 20px;
  }
  .imgcont {
    padding-right: 0px;
    width: 100%;
    height: 320px;
    padding: 0px;
  }
  .writeup {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 320px;
  }
.home {margin-top: 40px;}
.writeupinw {
  padding-top: 180px;
}

  .learn {
    width: 60%;
    padding: 7px;
    height: 30px;
    margin-left: 20%;
    display: block;
    padding-top: 4px;
    margin-top: 30px;
    background-color: white;
    color: #086060;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    font-weight: bold;
  }

      .ft {
    margin-top: -220px;
  }
}
</style>
