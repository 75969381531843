<template>
  <div class="cont">
    <Header />

    <div class="home">
      <div class="row row1">
        <div class="row imgcont">
          <img src="../../assets/ss.jpg" class="servicecardimg" />
        </div>

        <div class="writeup">
          <div class="writeupinw">
            <h4 class="sectionheader animate__animated animate__zoomIn">
              Graphics Designing
            </h4>
            <router-link to="/qoute" class="learn">GET A QOUTE </router-link>
          </div>
        </div>
        <!--- row end-->

        <div class="row row2 write">
          <div data-aos="slide-up">
            Beautiful hand-made professional graphic design for websites,
            branding and print materials. Since 2020 our graphic designers have
            woven their experience into every design project to drive our
            client’s success.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">BRINGING IDEAS TO LIFE</h4>
            Experience always matters a lot, when you are thinking about graphic
            designing in Umuahia. There are so many promising services, which
            are currently available over here, and those are procured from our
            team only. It is not always possible for the clients to visit your
            site. They do not have the liberty to enjoy a face to face
            conversation with the company’s owner. But they have some ideas or
            queries in their minds. Through proper graphical printed materials,
            clients and customers might get their answers fulfilled, as well.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">BUSINESS IDENTITY DESIGN</h4>
            Business identity is the single term to keep themselves apart from
            their competitors. It creates an immediate sense of identity through
            visual and textual content. Business identity tells whether you are
            unique or just another noise addition. Echelon Tech LLC is a
            Business identity design agency in Umuahia cover every single detail
            of your Business requirements that you wish to see in your brand
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">DIGITAL MEDIA DESIGNS</h4>
            Great design is what that not only attracts a consumer but also
            drops an impact on the viewers and this is what we do. Social media
            has always taken an important part in the success of business
            products and services. A large no of audiences has faith in your
            business because they found your social media account creative and
            real. If you need this your social media creative design needs to be
            very attractive.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">PRINT MEDIA DESIGNING</h4>
            Does your business card not having an impressive look? Does your
            profile take apart to comment on your high standards? Is your
            product inventory stays on the mind of the people after the first
            impression? If NO, then you must take a look at our print design
            services. Echelon tech LLC offers creative print designing services
            in Nigeria that help businesses to get professionally competitive
            spirit over the others in the industry.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">Why is Graphic Design Important?</h4>
            The most important part of any business is its branding. A good way
            to think about your brand is that it is essentially what people say
            about you when you’re not in the room. With that in mind, it can be
            said that for graphic design and marketing across print, web and
            social media a considerable amount of trust is placed with graphic
            design companies in Umuahia to create the foundation from which a
            business is built upon and remembered. Graphic designers must rely
            on creativity and experience to create a meaningful and memorable
            visual identity that resonates with the audience ultimately
            establishing a clear path of communication between a business and
            its target audience.
          </div>
        </div>
      </div>
      <!--- home end-->
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from ".././plugins/header.vue";
import Footer from ".././plugins/Footer.vue";
import Resmenu from ".././plugins/rmenu.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}
body {
  overflow: hidden;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
  padding: 0px;
}

.sectionheader {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
}

.home {
  padding: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 80px;
  padding-top: 0px;
  font-size: 18px;
}
.servicecardimg {
  width: 100%;
  height: 100%;
}
.imgcont {
  padding-right: 0px;
  text-align: center;
  width: 100%;
  height: 650px;
  margin-top: 20px;
}
.writeup {
  position: absolute;
  top: 75px;
  left: 0px;
  height: 654px;
  background-color: #086060ad;
  z-index: 2;
  width: 100%;
}

.writeupinw {
  width: 100%;
  text-align: center;
  padding-top: 250px;
  color: white;
}

.learn {
  width: 20%;
  padding: 10px;
  height: 40px;
  margin-left: 40%;
  display: block;
  padding-top: 5px;
  margin-top: 70px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .servicecardimg {
    margin-left: 0%;
  }
  #app {
    overflow-x: visible;
  }


  .sectionheader {
    margin-bottom: 20px;
    font-size: 19px;
    margin-top: -25%;
  }
  .main {
    font-size: 15px;
    color: white;
    text-align: justify;
    padding: 0px;
  }
  .write {
    padding: 0px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
    width: 100%;
    padding-left: 20px;
  }
  .imgcont {
    padding-right: 0px;
    width: 100%;
    height: 320px;
    padding: 0px;
  }
  .writeup {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 320px;
  }
.home {margin-top: 40px;}
.writeupinw {
  padding-top: 180px;
}

  .learn {
    width: 60%;
    padding: 7px;
    height: 30px;
    margin-left: 20%;
    display: block;
    padding-top: 4px;
    margin-top: 30px;
    background-color: white;
    color: #086060;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    font-weight: bold;
  }
    .ft {
    margin-top: -220px;
  }
}
</style>
