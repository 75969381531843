<template>
  <div class="above">
    <!-- <br /><br /> -->
    <div id="slider" class="">
      <div id="slide1">
        <div class="row slide row1">
          <div class="imgcont">
            <img src="../../assets/s1.jpg" alt="slide1" class="slide_img" />
          </div>

          <div class="writeup">
            <div class="writeupinw animate__animated animate__zoomIn">
              <div class="comentry">Web development</div>
              <div class="say resnone">
                Our web design firm has two very simple goals; to optimize every
                user‘s experience and hand-craft measurable impact for all our
                clients. we are commited to helping you grow your business.
              </div>
              <div class="none resview">
                Our web design firm has two very simple goals; to optimize every
                user‘s experience and hand-craft measurable impact for all our
                clients.
              </div>
              <br /><br />
              <router-link to="/web_development" class="action_btn login"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <!----------------------------------------------------------------------------- animate__animated animate__fadeInLeft ----->
      <div id="slide2" class="none">
        <div class="row slide row2">
          <div class="imgcont">
            <img src="../../assets/s4.jpg" alt="slide1" class="slide_img" />
          </div>
          <div class="writeup">
            <div class="writeupinw animate__animated animate__zoomIn">
              <div class="comentry">Consultancy</div>
              <div class="say resnone">
                Echelon Tech LLC is a business consultant focused on growth
                strategy and implementation. We enable clients to dream their
                wildest dreams. To Imagine their greatest success.To deliver on
                their ultimate vision. If you can Imagine your desired future,
                we can help you make it a reality. We bring clarity to your
                business and help it grow.
              </div>

              <div class="none resview">
                Echelon Tech LLC is a business consultant focused on growth
                strategy and implementation. We enable clients to dream their
                wildest dreams.
              </div>
              <br /><br />

              <router-link to="/consultancy" class="action_btn login"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <!----------------------------------------------------------------------------- animate__animated animate__fadeInLeft ----->
      <div id="slide3" class="none">
        <div class="row slide row2">
          <div class="imgcont">
            <img src="../../assets/s5.jpg" alt="slide1" class="slide_img" />
          </div>
          <div class="writeup">
            <div class="writeupinw animate__animated animate__zoomIn">
              <div class="comentry">Graphics Designing.</div>
              <div class="say resnone">
                Beautiful hand-made professional graphic design for websites,
                branding and print materials. Since 2020 our graphic designers
                have woven their experience into every design project to drive
                our client’s success.
              </div>
              <div class="none resview">
                Beautiful hand-made professional graphic design for websites,
                branding and print materials.
              </div>
              <br /><br />

              <router-link to="/graphics_designing" class="action_btn login"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="pagnitioncont slidepagnitioncont">
        <div class="spagnition spag1"></div>
        <div class="spagnition spag2"></div>
        <div class="spagnition spag3"></div>
      </div>
    </div>

    <!---slide for service-->
    <div class="service_slide" hidden>
      <div class="moveleft resnone">
        <i class="fa fa-angle-left favicon_style"></i>
        <!-- <img src="../../assets/arrow2.png" alt="" class="arrowimg" /> -->
      </div>
      <div class="moveright resnone">
        <i class="fa fa-angle-right favicon_style"></i>

        <!-- <img src="../../assets/arrow1.png" alt="" class="arrowimg" /> -->
      </div>

      <!--slide1-->
      <div class="slidecont" id="slidecont1" data-aos="zoom-in">
        <!--slides in slide 1-->
        <div
          class="Sslides Sslidesl card animate__animated animate__fadeInLeft"
        >
          <div class="service_name">Web development</div>
          <!-- <img src="../../assets/web.png" class="servicecardimg" /> -->
          <i class="fa fa-globe favicon_style2"></i>

          <div class="service_dtl resnone" title="best web developers">
            Our web design firm has two very simple goals; to optimize every
            user‘s experience and hand-craft measurable impact for all our
            clients. we are commited to helping you grow your business.
          </div>

          <div class="resview service_dtl">
            We build websites and apps with no down time online.
          </div>

          <router-link to="/web_development" class="learn"
            >Learn more</router-link
          >
        </div>

        <div class="Sslides card animate__animated animate__fadeInRight">
          <div class="service_name resnone">Mobile app development</div>
          <div class="service_name resview">App development</div>
          <!-- <img src="../../assets/app.png" class="servicecardimg" /> -->
          <i class="fa fa-app-store favicon_style2"></i>

          <div class="service_dtl resnone" title="best mobile developers">
            Our committed team of developers has intense knowldege,proficiency
            and skilled expertise as well as experience in android application
            Development.
          </div>
          <div class="resview service_dtl">
            We are geared towards making your dream app reality.
          </div>

          <router-link to="/app_development" class="learn"
            >Learn more</router-link
          >
        </div>
      </div>
      <!--slide2-->
      <div class="slidecont none" id="slidecont2" data-aos="zoom-in">
        <!--slides in slide 1-->
        <div
          class="Sslides Sslidesl card animate__animated animate__fadeInLeft"
        >
          <div class="service_name">Graphics Designing</div>
          <i class="fa fa-digital-tachograph favicon_style2"></i>

          <!-- <img src="../../assets/brand.png" class="servicecardimg" /> -->
          <div
            class="service_dtl resnone"
            title="Best graphic designers in Umuahia"
          >
            Beautiful hand-made professional graphic design for websites,
            branding and print materials.
          </div>
          <div
            class="service_dtl resview"
            title="Best graphic designers in Umuahia"
          >
            Beautiful hand-made professional graphic design for websites.
          </div>

          <router-link to="/graphics_designing" class="learn"
            >Learn more</router-link
          >
        </div>

        <div class="Sslides card animate__animated animate__fadeInRight">
          <div class="service_name resnone">Search Engine Optimization</div>
          <div class="service_name resview">SEO</div>

          <!-- <img src="../../assets/seo.png" class="servicecardimg" /> -->
          <i class="fas fa-searchengin favicon_style2"></i>

          <div
            class="service_dtl resnone"
            title="Best in search engine optimization service"
          >
            we are a strategic digital Marketing agency powered by data
            analytics, market research, and digital technologies.
          </div>

          <div
            class="service_dtl resview"
            title="Best in search engine optimization service"
          >
            Echelon Tech is a strategic digital Marketing agency powered by data
            analytics.
          </div>
          <router-link to="/SEO" class="learn">Learn more</router-link>
        </div>
      </div>

      <!--slide3-->
      <div class="slidecont none" id="slidecont3" data-aos="zoom-in">
        <!--slides in slide 1-->
        <div
          class="Sslides Sslidesl card animate__animated animate__fadeInLeft"
        >
          <div class="service_name">Digital marketing</div>
          <!-- <img src="../../assets/ux.png" class="servicecardimg" /> -->
          <i class="fas fa-bullhorn favicon_style2"></i>

          <div
            class="service_dtl resnone"
            title="Best in digital marketing service"
          >
            We generate demand for your Business, So You Could Continue What You
            Do The Best!
          </div>

          <div
            class="service_dtl resview"
            title="Best in digital marketing service"
          >
            We generate top-notche lead for your business, go global.
          </div>
          <router-link to="/digital_marketing" class="learn"
            >Learn more</router-link
          >
        </div>

        <div class="Sslides card animate__animated animate__fadeInRight">
          <div class="service_name">Consultancy</div>
          <!-- <img src="../../assets/digital.png" class="servicecardimg" /> -->
          <i class="fa fa-business-time favicon_style2"></i>

          <div class="service_dtl resnone" title="best web developers">
            Echelon Tech LLC is a business consultant focused on growth strategy
            and implementation.
          </div>
          <div class="service_dtl resview" title="best web developers">
            We are a business consultant focused on growth strategy.
          </div>
          <router-link to="/consultancy" class="learn">Learn more</router-link>
        </div>
      </div>

      <div class="pagnitioncont">
        <div class="pagnition pag1"></div>
        <div class="pagnition pag2"></div>
        <div class="pagnition pag3"></div>
      </div>
    </div>
    <!--end of slide-->
  </div>
</template>

<script>
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "Slide",
  components: {
    //Footer,
  },
  mounted() {
    AOS.init();
    $(".moveright").click(function () {
      service_animation();
    });

    $(".moveleft").click(function () {
      service_animationminus();
    });
  },
};

window.addEventListener("load", start);
window.addEventListener("load", start2);
var K = 0;
var N = 0;

////////////////////////////////////////////////////////////////////////////////////
var animation = () => {
  K++;
  if (K == 1) {
    $("#slide1,#slide3").hide();
    $("#slide2").show();
    $(".spag2").css("opacity", "1").css("background-color", "yellow");
    $(".spag1,.spag3").css("opacity", "0.7").css("background-color", "white");
  } else if (K == 2) {
    $("#slide3").show();
    $("#slide1,#slide2").hide();
    $(".spag3").css("opacity", "1").css("background-color", "yellow");
    $(".spag1,.spag2").css("opacity", "0.7").css("background-color", "white");
  } else if (K == 3) {
    $("#slide1").show();
    $("#slide2,#slide3").hide();
    $(".spag1").css("opacity", "1").css("background-color", "yellow");
    $(".spag2,.spag3").css("opacity", "0.7").css("background-color", "white");
    K = 0;
  }
};

var service_animation = () => {
  N++;

  if (N == 1) {
    $("#slidecont1,#slidecont3").hide();
    $("#slidecont2").show().css("display", "block");

    $(".pag2").css("opacity", "1");
    $(".pag1,.pag3").css("opacity", "0.2");
  } else if (N == 2) {
    $("#slidecont3").show().css("display", "inline-block");
    $("#slidecont2,#slidecont1").hide();

    $(".pag3").css("opacity", "1");
    $(".pag2,.pag1").css("opacity", "0.2");
  } else if (N == 3) {
    $("#slidecont1").show().css("display", "inline-block");
    $("#slidecont2,#slidecont3").hide();

    $(".pag1").css("opacity", "1");
    $(".pag2,.pag3").css("opacity", "0.2");
    N = 0;
  }
};

var service_animationminus = () => {
  N--;

  if (N == 0) {
    $("#slidecont1,#slidecont3").hide();
    $("#slidecont2").show().css("display", "block");

    $(".pag2").css("opacity", "1");
    $(".pag1,.pag3").css("opacity", "0.2");
  } else if (N == 1) {
    $("#slidecont3").show().css("display", "inline-block");
    $("#slidecont2,#slidecont1").hide();

    $(".pag3").css("opacity", "1");
    $(".pag2,.pag1").css("opacity", "0.2");
  } else if (N == -1) {
    $("#slidecont1").show().css("display", "inline-block");
    $("#slidecont2,#slidecont3").hide();

    $(".pag1").css("opacity", "1");
    $(".pag2,.pag3").css("opacity", "0.2");
    N = 0;
  }
};

var start = setInterval(animation, 16000);
var start2 = setInterval(service_animation, 10000);
</script>

<style scoped>
.favicon_style {
  font-size: 36px;
  color: #086060;
}
.favicon_style2 {
  font-size: 36px;
  color: #086060;
  margin-top: 10px;
  margin-bottom: 10px;
}
#slider {
  /*background-image: url("../../assets/bg1.jpg");*/
  background-size: 100%;
  margin-top: 60px;
  overflow: hidden;
  overflow-x: hidden;
}
.comentry {
  font-size: 35px;
  color: white;
  margin-bottom: 15px;
  margin-top: 100px;
  font-weight: bolder;
  font-family: "Bell MT", "Courier New CE", "Arial Black", monospace;
}
.say {
  font-size: 22px;
  color: white;
}
.action_btn {
  width: 260px;
  padding: 7px;
  height: 32px;
  margin-left: 0px;
  margin-right: 20px;
  display: block;
  border: 3px solid white;
  padding-top: 5px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 17px;
  border-radius: 4px;
  text-decoration: none;
  height: 40px;
  font-weight: bold;
}
.slide_img {
  width: 100%;
  height: 100%;
}
.imgcont {
  padding-right: 0px;
  text-align: center;
  width: 100%;
  height: 550px;
  margin-top: 20px;
}
.writeup {
  position: absolute;
  top: 75px;
  left: 0px;
  height: 554px;
  background-color: #086060ad;
  z-index: 2;
  width: 100%;
}

.servicecardimg {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.service_name {
  color: #086060;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}
/*#slider{box-shadow: 2px 2px 2px 2px #FDFDFFEF;}*/
.service_slide {
  background-color: #fdfdffef;
  padding: 40px;
  margin-bottom: 40px;
}
.slidecont {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Sslides {
  width: 40%;
  margin-left: 2%;
  margin-right: 2%;
  border: none;
  height: 260px;
  max-height: 300px;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.176);
  border-radius: 20px;
  padding: 12px;
  padding-top: 20px;

  display: inline-block;
}
.Sslidesl {
  margin-left: 4%;
}
.learn {
  width: 50%;
  padding: 7px;
  height: 35px;
  margin-left: 25%;
  display: block;
  padding-top: 5px;
  margin-top: 20px;
  background-color: #086060;
  color: white;
  text-align: center;
  font-size: 15px;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
}

.pagnition {
  height: 6px;
  width: 20px;
  border-radius: 26%;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #086060;
  display: inline-block;
  opacity: 0.2;
}
#pag1 {
  opacity: 1;
}
.pagnitioncont {
  margin-top: 20px;
  text-align: center;
}
.writeupinw {
  width: 60%;
  float: left;
  margin-left: 5%;
}

.none {
  display: none;
}
.slidepagnitioncont {
  position: absolute;
  left: 45%;
  margin-top: -50px;
  z-index: 2;
}
.spagnition {
  height: 15px;
  width: 15px;
  border-radius: 26%;
  margin-left: 15px;
  margin-right: 15px;
  background-color: white;
  display: inline-block;
  opacity: 0.7;
}
.spag1 {
  background-color: yellow;
  opacity: 1;
}

.moveleft {
  width: 50px;
  height: 50px;
  margin-top: 100px;
  position: absolute;
  border: 4px solid #086060;
  padding: 5px;
  left: 2%;
  z-index: 2;
  border-radius: 10px;
}
.moveright {
  width: 50px;
  height: 50px;
  margin-top: 100px;
  position: absolute;
  border: 4px solid #086060;
  padding: 5px;
  left: 95%;
  z-index: 2;
  border-radius: 10px;
}
.arrowimg {
  width: 100%;
  height: 100%;
}
.resview {
  display: none;
}

@media screen and (max-width: 480px) {
  /* #app {overflow-x: visible;} */
  .resnone {
    display: none;
  }
  .resview {
    display: block;
    color: white;
  }

  #slider {
    padding: 0px 00px 00px 0px;
    margin-top: 20px;
  }
  .imgcont {
    width: 100%;
    height: 320px;
  }
  .writeup {
    position: absolute;
    top: 57px;
    left: 0px;
    height: 305px;
    background-color: #086060 ae;
    z-index: 2;
    width: 100%;
  }

  .comentry {
    font-size: 26px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .say {
    font-size: 19px;
    color: white;
  }
  .writeupinw {
    width: 96%;
    float: left;
    margin-left: 1%;
  }
  .action_btn {
    width: 180px;
    padding: 7px;
    padding-top: 2px;
    margin-top: -20px;
    text-align: center;
    font-size: 17px;
    height: 35px;
    font-weight: bold;
  }
  .pagnition {
    height: 6px;
    width: 18px;
    border-radius: 26%;
    margin-left: 8px;
    margin-right: 8px;
  }
  .pagnitioncont {
    margin-top: 20px;
    text-align: center;
  }
  .service_slide {
    background-color: #fdfdffef;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .servicecardimg {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .service_name {
    color: #086060;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .Sslides {
    width: 43%;
    margin-left: 1%;
    margin-right: 1%;
    border: none;
    height: 160px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.176);
    border-radius: 20px;
    padding: 2px;
    display: inline-block;
    padding-top: 8px;
  }
  .service_dtl {
    color: black;
    font-size: 12px;
    text-align: left;
  }
  .learn {
    width: 90%;
    padding: 1px;
    height: 30px;
    margin-left: 5%;
    display: block;
    padding-top: 4px;
    margin-top: 4px;
    background-color: #086060;
    color: white;
    text-align: center;
    font-size: 13px;
    border-radius: 15px;
  }
  .slidepagnitioncont {
    left: 0%;
    margin-top: -100px;
    z-index: 2;
  }
  .slidepagnitioncont {
    position: absolute;
    left: 69%;
    margin-top: -30px;
    z-index: 2;
  }
  .spagnition {
    height: 10px;
    width: 10px;
    border-radius: 26%;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
    display: inline-block;
    opacity: 0.7;
  }
  .spag1 {
    background-color: yellow;
  }
}
</style>
