<template>
  <div class="cont">
    <Header />
    <div class="contact">
      <h4 class="sectionheader animate__animated animate__zoomIn">
        Contact us
      </h4>

      <h5 class="sayh animate__animated animate__zoomIn">
        Just fill in the contact form here and we’ll be sure to reply as fast as
        possible.
      </h5>

      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-6">
          <div class="contactform">
            <form action="">
              <div class="inputcont animate__animated animate__zoomIn">
                <input type="text" class="inputs" placeholder="Full name" />
              </div>

              <div class="inputcont animate__animated animate__zoomIn">
                <input
                  type="email"
                  class="inputs"
                  placeholder="Email Address"
                />
              </div>

              <textarea
                name=""
                id=""
                placeholder="Message"
                class="animate__animated animate__zoomIn"
              ></textarea>
              <br />
              <br />

              <div
                class="send animate__animated animate__zoomIn"
                @:click="alert('Message sent')"
              >
                Send Message
              </div>
            </form>
            <br />
          </div>
        </div>
        <div class="col-md-4 ot">
          <h5 class="bizh animate__animated animate__fadeInRight">
            Business Consultation
          </h5>
          <div class="say animate__animated animate__fadeInRight">
            For business plan submissions. Please submit using these.
          </div>

          <div class="othercontact">
            <!-- <img src="../assets/phone.png" alt="" class="contactimg" /> -->
            <i class="fas fa-phone-square favicon_style"></i>

            <a class="mcontact link" href="tel:09036343095"
              >(+234) 9036343095</a
            >
            <a class="mcontact link resnone" href="tel:09033795944">
              - (+234) 9033795944</a
            >
          </div>

          <div class="othercontact">
            <!-- <img src="../assets/email.png" alt="" class="contactimg" /> -->
            <i class="fas fa-mail-bulk favicon_style"></i>

            <a class="mcontact link" href="mailto:info@echelontech.com.ng"
              >info@echelontech.com.ng</a
            >
          </div>
          <!-- <div class="othercontact mailm animate__animated animate__fadeInRight">
<img src="../assets/location.png" alt="" class="contactimg">
<div class="mcontact">50 Aba Road, Umuahia<sup></sup> Abia State.</div>
</div> -->
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <div class="forres desknone">
      <!--<Resmenu />-->
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
import Header from "./plugins/header.vue";
import Footer from "./plugins/Footer.vue";
import Resmenu from "./plugins/rmenu.vue";

export default {
  name: "ContactPage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ft{margin-top: -100px;}

.favicon_style {
  font-size: 30px;
  color: #086060;
  display: inline-block;
}

.contact {
  padding: 20px;
  margin-top: 120px;
  padding-bottom: 40px;
}
.inputcont {
  width: 90%;
  margin-left: 5%;
  height: 40px;
  margin-bottom: 20px;
}
.label {
  color: #086060;
}
.inputs {
  width: 100%;
  height: 100%;
  padding: 4px;
  border: 2px solid #086060;
  border-radius: 5px;
  background-color: transparent;
  color: BLACK;
  padding: 10px;
  outline: none;
  outline: none;
}
.contactform {
  width: 100%;
}
textarea {
  border: 2px solid #086060;
  width: 100%;
  height: 120px;
  width: 90%;
  margin-left: 5%;
  border-radius: 10px;
  background-color: transparent;
  color: black;
  padding: 10px;
  outline: none;
}
.ml {
  margin-left: 5%;
}
.send {
  width: 90%;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  color: white;
  padding-top: 5px;
  background-color: #086060;
  margin-left: 5%;
}

.othercontact {
  color: black;
  font-size: 18px;
  margin-bottom: 40px;
  margin-top: 20px;
}
.contactimg {
  height: 30px;
  width: 30px;
}
.mcontact {
  display: inline-block;
  margin-left: 10px;
}
.say {
  color: black;
  font-weight: bold;
}

.sectionheader {
  color: #086060;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
}

.sayh {
  margin-bottom: 50px;
  color: /*rgb(37, 37, 204)*/ black;
  text-align: center;
}
.bizh {
  color: black;
  font-weight: bold;
}

a,
a:visited,
.link:hover {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 480px) {
  .contact {
    padding: 10px;
    margin-top: 80px;
    padding-bottom: 40px;
  }
  .resnone {
    display: none;
  }
  .contactform {
    margin-bottom: 40px;
    margin-top: -20px;
  }
  .ot {
    padding-left: 8%;
  }
  .mailm {
    margin-bottom: 20px;
  }
  .othercontact {
    color: black;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .contactimg {
    height: 24px;
    width: 24px;
  }
  .forres {
    display: block;
  }
  .sayh {
    font-size: 15px;
  }
}
</style>
