<template>
  <div id="app">
    <!--<Header />-->

    <router-view></router-view>

    <!--<Footer />-->
  </div>
</template>

<script>
//import Header from './components/plugins/header.vue'   leave-active-class="animate__animated animate__fadeOut"
//import Footer from './components/Footer.vue'

export default {
  name: "App",
  components: {
    //  Header,
    //  Footer
  },
};
</script>

<style>
.contain {
  margin-top: 0px;
  padding-bottom: 0px;
  background-color: white;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "Segoe UI", Tahoma, Geneva,
    Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #06080b;
  margin-top: 0px;
  background-color: white;
  padding-bottom: 0px;
  padding: 0px;
}


.sidebar-inner {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Handle portion of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #086060a2; /* Set the color of the scrollbar thumb */
}

/* Track portion of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the scrollbar track */
}

@media screen and (max-width: 480px) {
}
</style>
<!-- #1a73e8 -->
<!-- #086060 -->
