<template>
  <div class="cont">
    <Header />

    <div class="home">
      <div class="row row1">
        <div class="row imgcont">
          <img src="../../assets/ss.jpg" class="servicecardimg" />
        </div>

        <div class="writeup">
          <div class="writeupinw">
            <h4 class="sectionheader animate__animated animate__zoomIn">
              Digital Marketing
            </h4>
            <router-link to="/qoute" class="learn">GET A QOUTE </router-link>
          </div>
        </div>
        <!--- row end-->

        <div class="row row2 write">
          <div data-aos="slide-up">
            We generate demand for your Business, So You Could Continue What You
            Do The Best! A data-driven team of digital marketing experts, who
            can build a goal-centric online presence for your business using a
            full-funnel marketing approach
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">
              INTEGRATED DIGITAL MARKETING SOLUTIONS
            </h4>
            Searching for Digital Marketing Companies in Umuahia? We are Top
            Digital Marketing Agency in Umuahia to plan your online marketing
            strategies efficiently. Search & Social advertising creates a
            considerable impact on the buying decisions of users thereby giving
            successful conversions. We Are Best Digital Marketing Agency in
            Umuahia. Our course of action is simple, Yet incredibly powerful. A
            system that helps our clients turn their vision into reality
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">TARGET</h4>
            Understanding your target audience is the foundation for us to
            create a custom online marketing program that suits your budget,
            business goals and timeline. Once we undesrstand their needs, we
            position your brand in places your target auidence
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">ATTRACT</h4>
            We identify consumer needs by continuosuly tracking the behaviour
            and online interactions of your users. Using Google ads campaignsm
            SEO, video marketing, blogging and content marketing strategies, to
            attract and enagage your audience.
          </div>

          <div data-aos="slide-up">
            <h4 class="sectionheadersub">CONVERT</h4>
            Our Conversion optimization strategies are made to reduce any
            friction on the consumer end. We set up enticing CTAs, on the point
            content, fast loading pages leading them to a specialized landing
            page and collecting data through simple forms.
          </div>
        </div>
      </div>
      <!--- home end-->
    </div>

    <Resmenu />

    <Footer class="ft"/>
  </div>
</template>

<script>
//import $ from "jquery"

import Header from ".././plugins/header.vue";
import Footer from ".././plugins/Footer.vue";
import Resmenu from ".././plugins/rmenu.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    Resmenu,
  },
  mounted() {
    AOS.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #2c3e50 ;box-shadow: 4px 4px 4px 4px rgb(236, 236, 236)-->
<style scoped>
.ft{margin-top: -170px;}
body {
  overflow: hidden;
}
.sectionheadersub {
  color: #086060;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
  padding: 0px;
}

.sectionheader {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 35px;
}

.home {
  padding: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 50px;
}
.main {
  font-size: 18px;
  color: white;
  text-align: justify;
}

.row {
  margin-bottom: 40px;
}
.write {
  padding: 80px;
  padding-top: 0px;
  font-size: 18px;
}
.servicecardimg {
  width: 100%;
  height: 100%;
}
.imgcont {
  padding-right: 0px;
  text-align: center;
  width: 100%;
  height: 650px;
  margin-top: 20px;
}
.writeup {
  position: absolute;
  top: 75px;
  left: 0px;
  height: 654px;
  background-color: #086060ad;
  z-index: 2;
  width: 100%;
}

.writeupinw {
  width: 100%;
  text-align: center;
  padding-top: 250px;
  color: white;
}

.learn {
  width: 20%;
  padding: 10px;
  height: 40px;
  margin-left: 40%;
  display: block;
  padding-top: 5px;
  margin-top: 70px;
  background-color: white;
  color: #086060;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .servicecardimg {
    margin-left: 0%;
  }
  #app {
    overflow-x: visible;
  }


  .sectionheader {
    margin-bottom: 20px;
    font-size: 19px;
    margin-top: -25%;
  }
  .main {
    font-size: 15px;
    color: white;
    text-align: justify;
    padding: 0px;
  }
  .write {
    padding: 0px;
    padding-top: 0px;
    font-size: 15px;
    text-align: justify;
    width: 100%;
    padding-left: 20px;
  }
  .imgcont {
    padding-right: 0px;
    width: 100%;
    height: 320px;
    padding: 0px;
  }
  .writeup {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 320px;
  }
.home {margin-top: 40px;}
.writeupinw {
  padding-top: 180px;
}

  .learn {
    width: 60%;
    padding: 7px;
    height: 30px;
    margin-left: 20%;
    display: block;
    padding-top: 4px;
    margin-top: 30px;
    background-color: white;
    color: #086060;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    font-weight: bold;
  }
    .ft {
    margin-top: -220px;
  }
}
</style>
